import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import { Box } from '@fivehealth/botero';

interface IQuickReplyText {
    previewMode: boolean;
    text: string;
    onClick: (text: string) => void;
    styles?: any;
}

const QuickReplyContainerEnabled: any = styled(Box)`
    border: 1px solid ${Colors.blue};
    border-radius: 100px;
    width: fit-content;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    :hover {
        background: ${Colors.quickReplyHoverBg};
    }
`;

const QuickReplyContainerDisabled: any = styled(Box)`
    border: 1px solid ${Colors.blue};
    border-radius: 100px;
    width: fit-content;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: not-allowed;
`;

const QuickReplySpan = styled.span`
    font-family: Inter;
    line-height: 20px;
    color: ${Colors.blue};
`

const QuickReplyText = (props: IQuickReplyText) => {
    const QuickReplyContainer = props.previewMode ? QuickReplyContainerDisabled : QuickReplyContainerEnabled;
    return (
        <QuickReplyContainer
            p={["10px", "6px 16px"]}
            {...props.styles}
            onClick={() => {
                !props.previewMode && props.onClick(props.text);
            }}
            disabled={true}
            props={props}
        >
            <QuickReplySpan>{props.text}</QuickReplySpan>
        </QuickReplyContainer>
    );
};

export default QuickReplyText;
