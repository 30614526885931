import { gql } from "graphql-request";
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_PUSHER: any = gql`
  query getProviderApplicationSettings($uid: String!) {
    ratatoskrProviderApplication(uid: $uid) {
      settings
    }
  }
`


export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_PUSHER,
  queryType: "query",
  baseQueryKey: "ratatoskrProviderApplication",
  keepPreviousData: true
});
