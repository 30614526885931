import 'url-search-params-polyfill';
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import Pusher from "pusher-js";
import { AppDataProvider, IModalContent, useAppData } from "./context/AppDataContext";
import { getDateTimeNow } from "./utils/formatISODateTime";
import { get, isEmpty, isNull } from "lodash";
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom'
import useDeviceDetect from './hooks/useDeviceDetect';

import {
    faTimes
} from '@fortawesome/pro-regular-svg-icons';

import {
    ThemeProvider,
    Flex,
    ApiProvider,
    AuthProvider,
    Box,
    useAuth,
    FAIcon,
    useApi,
} from '@fivehealth/botero';

import theme from "./theme";
import { ErrorBoundary } from 'react-error-boundary';
import Sidebar from "./components/Sidebar";
import MainContent from "./components/MainContent";
import Routes from "./Routes";
import { Colors } from "./constants/colors";
import { assets } from "./assets/images";
import styled, { keyframes } from "styled-components";
import {
    fetchAllMessages,
    generateChannelUid, getLoggedInTime,
    getOrgHeaderImg,
    getOrganisation, getPDFViewerDomain,
    hasPublicSession, isWidget,
    updateMessagesQueryData, updateStitchV2ToStitchV3
} from "./utils/appUtils";
import { GRAPHQL_DOCUMENT } from "./api/queries/useMessage";

import { APP_INFO, IS_FORMULARY_REGEX, LOGIN_URL, PUBLIC_PROVIDERS, QrCodeLOGIN_URL } from "./constants/variables";
import { GRAPHQL_DOCUMENT_PUSHER } from "./api/queries/useRatatoskrProviderApplication";
import { GRAPHQL_DOCUMENT_HOSPITAL_REGISTER_DEVICE } from "./api/queries/useHospitalRegisterDevice";
import { GRAPHQL_DOCUMENT_HOSPITAL_REGISTERABLE_DEVICE } from "./api/queries/useHospitalRegisterableDevice";
import Login from "./views/Login";
import { GRAPHQL_DOCUMENT_HOSPITAL_PROFILE } from "./api/queries/useHospitalProfile";
import queryString from 'query-string';
import { GRAPHQL_DOCUMENT_HEIMDALL_SESSION } from "./api/queries/useHeimdallSession";

const LogoWithText = styled(Box)`
    margin-left: 15%;
    margin-top: 32px;
`

const IconContainer = styled(Box)`
    padding-right: 20px;
    cursor: pointer;
`

const StyledFlex = styled(Flex)`
    position: relative;
    height: 91vh;
    display: block;
`

const Modal = styled(Box)`
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: white;
    overflow-y: hidden;
    transition: height 0.02s linear;
`

const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(41px) translateY(0px);
    }

    to {
        opacity: 1;
        transform: none;
    }
`;

const Container = styled(Flex)`
    width: 100%;
    ${({ modal }: any) => modal &&
        `
        animation: ${fadeInUp} 0.1s linear;
        transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        position: relative;
        z-index: 999 !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        height: 100% !important;
        width: 100% !important;
        padding-top: 55px;
        // background: rgba(0, 0, 0, 0.3);
        border-radius: 12px;
        display: block;
        margin: 2px;
    `}
`

const Header = styled(Box)`
    height: 25%;
    background: ${Colors.blue};
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const DialogOverlay = styled(Box)`
  background: #00000075;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  visibility: visible;
  animation: ${fadeIn} 0.1s linear;
  transition: visibility 0.1s linear;
  border-radius: 15px;
  z-index: 999;
  height: calc(90% - 40px);
  padding-top: 40px;
`;

const apiQueryCtx = require.context('./api/queries', true, /.js$/);
const queryMapping = apiQueryCtx.keys().reduce((acc: any, filename: any) => {
    const [key] = filename.split('./').pop().split('.js');
    return {
        ...acc,
        [key]: apiQueryCtx(filename).default,
    };
}, {});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
        },
    },
});

const org = getOrganisation()

const AppContainer = () => {
    const [cookies, setCookie] = useCookies([APP_INFO.cookieName]);

    const [headerImageUpdated, setHeaderImageUpdated] = useState('')

    const { login, authState, logout } = useAuth();
    const {
        modal, resetModal, updateUser,
        closeMobileLoginModal,
        showMobileLoginModal,
        mobileLoginOption,
        state
    } = useAppData()
    const [showModal, setShowModal] = useState(false)
    const { client } = useApi()
    const queryClient = useQueryClient()
    const history = useHistory();
    const { isMobile } = useDeviceDetect();

    const {
        queries: { useHeimdallPublicSession },
    } = useApi({ queries: ["useHeimdallPublicSession"] });


    const [visibilityState, setVisibilityState] = useState(false)

    const sessionStorageToken = sessionStorage.getItem(APP_INFO.cookieName);
    const token = sessionStorageToken || (cookies && cookies[APP_INFO.cookieName]?.session);


    const { mutateAsync: getHeimdallPublicSession } =
        useHeimdallPublicSession({
            retry: 3,
            variables: {},
            onSuccess: ({ data }: any) => {
                const { heimdallAuthorizationFlow } = data
                if (heimdallAuthorizationFlow) {
                    const sessionId = get(heimdallAuthorizationFlow, 'session.uid')
                    login({ token: sessionId });
                }
            },
            onError: (error: any) => {
                //TODO: Display error message unable to start Bot MD
            }
        });

    useEffect(() => {
        if (token && !authState.authenticated) {
            setTimeout(() => {
                login({ token });
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);


    const initHeimdallPublicSession = () => {
        if (org && hasPublicSession(org)) {
            const { id, organisationKey } = PUBLIC_PROVIDERS[org];
            getHeimdallPublicSession({
                input: {
                    providerApplicationUid: id,
                    providerInput: {
                        access_token: "HLzeHEM3tqP6C9osONPB"
                    },
                    applicationInput: {
                        allow_guest: false,
                        organization_key: organisationKey
                    }
                }
            })
        }
    }

    useEffect(() => {
        setShowModal(!!modal.url)
        if (!isMobile) {
            closeMobileLoginModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal, isMobile])

    useEffect(() => {
        const session = !!new URLSearchParams(document.location.search).get("session")
        if (session) {
            setTimeout(() => {
                history.push('/');
            }, 1000);
        }
    })


    useEffect(() => {
        if (authState.authenticated && (isWidget || !isEmpty(cookies))) {
            getPusherDetails()
        }

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                if (cookies[APP_INFO.cookieName]?.session) {
                    setVisibilityState(true)
                }
            } else {
                setVisibilityState(false)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState])

    useEffect(() => {
        if (visibilityState && authState.authenticated) {
            getHeimdallSession()
            fetchMessages()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibilityState])

    useEffect(() => {
        window.addEventListener('message', message => {
            if (message.data.session) {
                setCookie(APP_INFO.cookieName, {
                    ...(cookies[APP_INFO.cookieName] || {}),
                    session: message.data.session,
                    channelUid: generateChannelUid(cookies, setCookie)
                }, { path: '/' });
                resetModal()
                setTimeout(() => {
                    history.push("/");
                }, 2000);
                login({ token: message.data.session })
            }

            // set header image in localStorage send by web-widget
            if (message.data.headerImage) {
                console.log('---HEADER IMAGE---', message.data.headerImage)
                setHeaderImageUpdated(message.data.headerImage)
            }

            if (message.data.initHeimdallPublicSession) {
                console.log('---INIT---:', message.data)
                initHeimdallPublicSession()
                if (message.data.headerImage) {
                    console.log('---HEADER IMAGE---', message.data.headerImage)
                    localStorage.setItem("botmd:brandLogo", message.data.headerImage);
                    setHeaderImageUpdated(message.data.headerImage)
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getHeimdallSession = async () => {
        const { heimdallSession } = await client.request(GRAPHQL_DOCUMENT_HEIMDALL_SESSION, { uid: token })
        if (!heimdallSession) {
            logout()
        }
    }

    const getPusherDetails = async () => {
        try {
            if (authState.authenticated && authState.token && authState.token !== "null") {
                const data = await client.request(GRAPHQL_DOCUMENT_PUSHER, { uid: APP_INFO.pusherApplicationId })
                registerHospitalDevice(data)
            }
        } catch (error) {
            logout()
        }
    }

    const registerableHospitalDevice = async (deviceUid: string) => {
        try {
            const data = await client.request(GRAPHQL_DOCUMENT_HOSPITAL_REGISTERABLE_DEVICE, { deviceUid });
            const registerable = get(data, 'hospitalRegisterableDevice.registerable');
            return registerable;
        } catch (error) {
            logout();
        }
    }

    const registerHospitalDevice = async (pusherDetails: any) => {
        if (isNull(state.user)) {
            let channelUid = cookies[APP_INFO.cookieName]?.channelUid || generateChannelUid(cookies, setCookie);

            const getDeviceUidFromChannelUid = (channelUid: string) => {
                return channelUid.startsWith("private-") ? channelUid.slice("private-".length) : channelUid;
            }

            let registerable = await registerableHospitalDevice(getDeviceUidFromChannelUid(channelUid));
            if (!registerable) {
                channelUid = generateChannelUid(cookies, setCookie);
                registerable = await registerableHospitalDevice(getDeviceUidFromChannelUid(channelUid));
                if (!registerable) {
                    return logout();
                }
            }
            const pusherResponse = get(pusherDetails, "ratatoskrProviderApplication.settings.pusher");
            const input = {
                providerApplication: APP_INFO.pusherApplicationId,
                pusher: {
                    channel: channelUid
                },
                deviceMetadata: {
                    "platform": "web",
                    "channel": "web"
                }
            }
            try {
                const { hospitalRegisterDevice } = await client.request(GRAPHQL_DOCUMENT_HOSPITAL_REGISTER_DEVICE, { input })
                if (hospitalRegisterDevice) {
                    subscribeToChat(pusherResponse, channelUid, true)
                }

                const { hospitalProfile } = await client.request(GRAPHQL_DOCUMENT_HOSPITAL_PROFILE)
                if (hospitalProfile) {
                    updateUser(hospitalProfile)
                }

            } catch (error) {
                logout()
            }
        }
    }

    const fetchMessages = async () => {
        try {
            await fetchAllMessages(queryClient, client)
        } catch (error) {
            logout()
            if (isMobile) {
                window.document.location.reload()
            }
        }
    }

    const mapMessages = (data: any) => {
        let messages: any = []

        data.forEach((element: any, index: number) => {
            element.createdOn = getDateTimeNow();

            const messageType = get(element, 'messageContent.message.type')
            if (messageType === "text") {
                const content = get(element, 'messageContent.message.text')
                if (content.includes("You have been logged out")) {
                    logout()
                }
            }

            const direction = get(element, 'direction')
            if (isWidget && direction === "outgoing") {
                window.parent.postMessage({ beforeDisplay: element.messageContent }, "*");
            }

            const isTruncated = get(element, 'metadata.pusher.truncated');
            if (isTruncated) {
                fetchMessages()
                return;
            }
            messages[index] = { node: { ...element } };
        });

        if (authState.authenticated) {
            updateMessagesQueryData(queryClient, messages);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fetchTruncatedMessage = async (uid: string) => {
        if (isEmpty(authState.token)) {
            logout()
            return;
        }

        const data = await queryClient.fetchQuery(["messages", uid], () => client.request(GRAPHQL_DOCUMENT, { uid }))
        const { hospitalMessage } = data;
        const nonTruncatedMessage = {
            ...hospitalMessage
        };
        nonTruncatedMessage.metadata.pusher.truncated = false;

        updateMessagesQueryData(queryClient, [{ node: { ...nonTruncatedMessage } }]);
    };

    const subscribeToChat = (pusherResponse: any, channelUid: string, fetchInitialMessages = false) => {
        const pusher = new Pusher(pusherResponse.key, {
            cluster: "ap1",
            authEndpoint: pusherResponse.authEndpoint,
            auth: {
                headers: {
                    "X-Session": authState.token,
                    "X-Pusher-Key": pusherResponse.key,
                },
            },
        });

        const channel = pusher.subscribe(channelUid);
        channel.unbind();
        channel.bind("messages", mapMessages);

        if (fetchInitialMessages) {
            setTimeout(async () => {
                await fetchMessages()
            }, 1500);
        }
    };

    const parseModalUrl = (modal: IModalContent) => {
        let { contentType, url } = modal;

        const isFormularyUrl = url.match(IS_FORMULARY_REGEX);

        if (isFormularyUrl && !url.includes('roche_products')) {
            let parsedUrl = url
            let qs = ''
            if (url.indexOf('?') > -1) {
                parsedUrl = url.split('?')[0]
                qs = url.split('?')[1]
            }

            const parsedQuery = queryString.parse(`?${qs}`);
            qs = queryString.stringify({
                ...parsedQuery,
                authorization: `Bearer ${authState.token}`,
            });

            url = `${parsedUrl}?${qs}`
        }

        const updatedUrl = isFormularyUrl ? url : updateStitchV2ToStitchV3(authState, url);

        if (contentType?.includes("pdf") || updatedUrl?.includes("pdf")  || updatedUrl?.includes("xlsx") || updatedUrl?.includes("docx")) {
            return `https://${getPDFViewerDomain()}/?document=${updatedUrl}`
    
        }
        return updatedUrl
    }

    return (
        <div style={{ background: Colors.menuGrey, height: '100vh', overflow: "hidden" }}>
            <Header display={["none", "block"]}>
                <LogoWithText as="img" src={assets.botLogoWithText} />
            </Header>

            <StyledFlex mt={["0px", "-100px"]}>
                {(!authState.authenticated && !isMobile) && (<Login url={QrCodeLOGIN_URL} />)}
                {(authState.authenticated || isMobile) && (
                    <Container minHeight={["100vh", "inherit"]} pl={["0", "15%"]} pr={["0", "15%"]}>
                        <Sidebar />
                        <MainContent headerImage={getOrgHeaderImg() || headerImageUpdated}>
                            <Routes />
                        </MainContent>
                    </Container>
                )}

                {modal.url && modal.contentType && modal.contentType.includes('image/') && (
                    <DialogOverlay
                        onClick={() => { resetModal() }}
                        mr={[0, "15%"]}
                        ml={[0, "15%"]}>
                        <Box
                            height="8vh"
                            borderTopLeftRadius={[0, 12]}
                            borderTopRightRadius={[0, 12]}
                            bg={["white"]}
                            open={showModal}>
                            <IconContainer paddingTop={["15px", "10px"]} onClick={() => { resetModal() }}>
                                <FAIcon
                                    icon={faTimes}
                                    style={{
                                        fontSize: 24,
                                        color: Colors.black,
                                        float: "right",
                                    }}
                                />
                            </IconContainer>
                        </Box>
                        <Modal
                            {...showModal && { opacity: 1, height: ["100vh", "calc(95% + 10px)"] }}>
                            <div style={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%"
                            }}>
                                <img style={{ height: "100%", width: "100%" }} src={parseModalUrl(modal)} alt="" />
                            </div>
                        </Modal>
                    </DialogOverlay>
                )}

                {modal.url && (!modal.contentType || !modal.contentType?.includes('image/')) && (
                    <DialogOverlay
                        onClick={() => { resetModal() }}
                        mr={[0, "15%"]}
                        ml={[0, "15%"]}>
                        <Box
                            height="8vh"
                            borderTopLeftRadius={[0, 12]}
                            borderTopRightRadius={[0, 12]}
                            bg={["white"]}
                            open={showModal}>
                            <IconContainer paddingTop={["15px", "10px"]} onClick={() => { resetModal() }}>
                                <FAIcon
                                    icon={faTimes}
                                    style={{
                                        fontSize: 24,
                                        color: Colors.black,
                                        float: "right",
                                    }}
                                />
                            </IconContainer>
                        </Box>
                        <Modal
                            {...showModal && { opacity: 1, height: ["100vh", "calc(95% + 10px)"] }}>
                            <iframe
                                frameBorder="0"
                                title={modal.url}
                                width="100%" height="100%"
                                src={parseModalUrl(modal)}
                            />
                        </Modal>
                    </DialogOverlay>
                )}

                {isMobile && showMobileLoginModal && !authState.authenticated && (
                    <DialogOverlay bg="#11182466 !important" borderRadius="0 !important">
                        <Box
                            borderTopLeftRadius={[0, 12]}
                            borderTopRightRadius={[0, 12]}
                            height="8vh !important"
                            bg={["white"]}
                            open={showModal}>
                            <IconContainer pt="20px" pb="20px" onClick={() => { closeMobileLoginModal() }}>
                                <FAIcon
                                    icon={faTimes}
                                    style={{
                                        fontSize: 24,
                                        color: Colors.black,
                                        float: "right",
                                    }}
                                />
                            </IconContainer>
                        </Box>
                        <Modal
                            zIndex={999}
                            height={"100vh"}
                        >
                            <Login url={mobileLoginOption} />
                        </Modal>
                    </DialogOverlay>
                )}
            </StyledFlex>
        </div>
    )
}

const App = () => {
    const [cookies, setCookie, removeCookie] = useCookies([APP_INFO.cookieName]);
    const { resetUser } = useAppData()
    const history = useHistory();

    useEffect(() => {
        if (isEmpty(cookies)) {
            generateChannelUid(cookies, setCookie)
        }
    })

    const onLoginCallback = (data: string) => {
        setCookie(APP_INFO.cookieName, {
            ...(cookies[APP_INFO.cookieName] || {}),
            session: data
        }, { path: '/' });
        getLoggedInTime()
        return {
            authenticated: data && data !== "null",
            token: data
        }
    };

    const onLogOutCallback = () => {
        sessionStorage.removeItem(APP_INFO.loggedInUserTimestamp)
        sessionStorage.removeItem(APP_INFO.cookieName);
        removeCookie(APP_INFO.cookieName)
        resetUser()
        if (!isWidget) {
            history.push("/");
        }
    }

    const onUnhandledError = () => <h3> An error occured </h3>;

    return (
        <AuthProvider
            loginUrl={LOGIN_URL}
            redirectPath={APP_INFO.redirectPath}
            onLogin={onLoginCallback}
            onLogout={onLogOutCallback}
            initialState={{
                token: sessionStorage.getItem(APP_INFO.cookieName),
                authenticated: !!sessionStorage.getItem(APP_INFO.cookieName)
            }}
        >
            <AppDataProvider>
                <ApiProvider endpoint={APP_INFO.gqlEndpoint} queryMapping={queryMapping}>
                    <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={theme}>
                            <ErrorBoundary fallbackRender={onUnhandledError}>
                                <AppContainer />
                            </ErrorBoundary>
                        </ThemeProvider>
                    </QueryClientProvider>
                </ApiProvider>
            </AppDataProvider>
        </AuthProvider>
    );
};

export default App;
