import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Chats from './views/Chats';
import {
  useAuth,
} from '@fivehealth/botero';

import { useCookies } from 'react-cookie';
import { APP_INFO } from './constants/variables';
import Favorites from './views/Favorites';


const Routes = () => {
  const { login, authState } = useAuth();
  const [cookies] = useCookies([APP_INFO.cookieName]);
  const token = cookies && cookies[APP_INFO.cookieName]?.session;

  useEffect(() => {
    if (token && !authState.authenticated) {
      login({ token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Switch>
      <Route exact path="/">
        <Chats />
      </Route>
      <Route exact={false} path="/login/callback">
        <Chats />
      </Route>
      <Route exact path="/favorites">
        <Favorites />
      </Route>
    </Switch>
  );
}

export default Routes;
