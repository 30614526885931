import { gql } from "graphql-request";
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_HOSPITAL_REGISTER_DEVICE: any = gql`
  mutation hospitalRegisterDevice($input: HospitalRegisterDeviceInput!) {
    hospitalRegisterDevice(input: $input) {
      device {
        uid
        createdOn
      }
      profile {
        uid
        firstName
        lastName
        department
        designation
        superpower
        picture
      }
    }
  }
`


export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_HOSPITAL_REGISTER_DEVICE,
  queryType: "mutation",
  baseQueryKey: "hospitalRegisterDevice",
});
