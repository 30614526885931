import { gql } from "graphql-request";
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT: any = gql`
  query hospitalMessage($uid: String!) {
    hospitalMessage(uid: $uid) {
      id
      uid
      direction
      messageContent
      metadata
      createdOn
    }
  }
`


export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: "query",
  baseQueryKey: "message",
  keepPreviousData: true
});
