import { LOGIN_URL, QrCodeLOGIN_URL } from "./variables";
import { DateTime } from "luxon";

/* eslint no-template-curly-in-string: 0 */
export const SessionExpiredMessage = {
  "uid": "hNdUXvE3HHGss6ARuhcyYc4IPqKds1lt",
  "direction": "outgoing",
  "messageContent": {
    "type": "message",
    "message": {
      "type": "sections",
      "sections": [{
        "type": "paragraphs",
        "paragraphs": [{
          "text": "Your connection may have timed out. Please try refreshing the webpage again, thank you!",
          "type": "text"
        }]
      }, {
        "type": "actions",
        "actions": [
          {
            "type": "button",
            "button": {
              "url": "https://bot-md.typeform.com/to/cw6hXn#user_uuid=${userUid}&device_uuid=${channelUid}&institution=${organization_key}",
              "text": "Give Feedback",
              "style": "cta",
              "content_type": "text/html",
              "external_browser": true
            }
          }
        ]
      }]
    }
  },
  "metadata": {
    "pusher": {
      "status": "sent"
    }
  },
  "createdOn": DateTime.now().minus({ seconds: 2 }).toString()
}


export const RocheWelcomeMessage = {
  "uid": "hNdUXvE3HHGss6ARuhcyYc7IPqKds1lr",
  "direction": "outgoing",
  "messageContent": {
    "type": "message",
    "message": {
      "type": "sections",
      "sections": [{
        "type": "image",
        "image": {
          "url": "https://t.botmd.io/roche_logo.png"
        }
      }, {
        "type": "paragraphs",
        "paragraphs": [{
          "text": "Hello! I'm Roche RAY Bot powered by Bot MD 😎👋 I can help you search information on Roche Singapore products and services. Tap a button below or log in to view exclusive Roche events and content👇",
          "type": "text"
        }]
      }, {
        "type": "actions",
        "actions": [
          {
            "type": "button",
            "button": {
              "url": "https://static.production.botmd.io/botero-auth/index.html?cache=1631063670430#/?smooch_id=0058995a67ce58a87c83f55e",
              "text": "👩‍⚕️ Doctor Login",
              "style": "cta",
              "content_type": "text/html",
              "external_browser": true,
            }
          }
        ]
      }]
    }
  },
  "metadata": {
    "pusher": {
      "status": "sent"
    }
  },
  "createdOn": DateTime.now().minus({ seconds: 2 }).toString()
}

export const WelcomeMessage = {
  "uid": "hNdUXvE3HHGss6ARuhcyYc7IPqKds1lx",
  "direction": "outgoing",
  "messageContent": {
    "type": "message",
    "message": {
      "type": "sections",
      "sections": [{
        "type": "image",
        "image": {
          "url": "https://t.botmd.io/BotMD_padding.png"
        }
      }, {
        "type": "paragraphs",
        "paragraphs": [{
          "text": "Welcome to Bot MD 😎👋 ! Bot MD requires all users to be signed in. Please sign in using one of the options below 👇.",
          "type": "text"
        }]
      }, {
        "type": "actions",
        "actions": [
          {
            "type": "button",
            "button": {
              "url": LOGIN_URL,
              "text": "SMS Login",
              "style": "cta",
              "content_type": "text/html",
              "external_browser": false,
            }
          }, {
            "type": "button",
            "button": {
              "url": `${QrCodeLOGIN_URL}`,
              "text": "QR Code Login",
              "style": "cta",
              "content_type": "text/html",
              "external_browser": false
            }
          }
        ]
      }]
    }
  },
  "metadata": {
    "pusher": {
      "status": "sent"
    }
  },
  "createdOn": DateTime.now().minus({ seconds: 2 }).toString()
}