import React, { useEffect, useState } from 'react';
import { Box } from '@fivehealth/botero';
import Header from '../Header';
import { Colors } from '../../constants/colors';
import { useLocation } from 'react-router-dom';
import useDeviceDetect from '../../hooks/useDeviceDetect';

interface IMainContent {
  children: React.ReactNode
  headerImage?: string
}

const MainContent = ({ children, headerImage }: IMainContent) => {
  const location = useLocation();
  const { isMobile } = useDeviceDetect();
  const [showHeader, setShowHeader] = useState(true)

  useEffect(() => {
    setShowHeader(location.pathname === '/' || isMobile)
  }, [location, isMobile])

  return (
    <Box
      borderRadius={["16px", "0 16px 16px 0"]}
      bg={Colors.white}
      flexGrow={99999}
      flexBasis={0}
      minWidth={320}>
      {showHeader && (<Header headerImage={headerImage} />)}
      {children}
    </Box>
  )
};
export default MainContent;
