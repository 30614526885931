import React from "react";
import { debounce } from "lodash";
import { displayMobileView } from "../../utils/appUtils";

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(displayMobileView());

  React.useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setMobile(displayMobileView())
    }, 500);

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  return { isMobile };
}