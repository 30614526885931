import { gql } from "graphql-request";
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_HOSPITAL_PROFILE = gql`
  query currentSession {
    hospitalProfile {
      uid
      firstName
      lastName
      department
      designation
      superpower
      picture
      avatar
      hospital {
        name
        settings
      }
  }
}
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_HOSPITAL_PROFILE,
  queryType: "query",
  baseQueryKey: "hospitalProfile",
  keepPreviousData: true
});
