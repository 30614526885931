import { Flex, Box } from '@fivehealth/botero';
import styled from 'styled-components';
import { assets } from '../../assets/images';
import { Colors } from '../../constants/colors';
import LogoWithIndicator from '../LogoWithIndicator';
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { memo } from 'react';

const Bot = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
`

const OnlineStatus = styled.div`
  color: ${Colors.black}
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
`

const StyledFlex = styled(Flex)`
  border-bottom: 1px solid ${Colors.borderLine};
}
`

const isWidget = !!new URLSearchParams(document.location.search).get("widget")

interface HeaderProps {
  headerImage?: string
}

const Header = memo<HeaderProps>(({ headerImage }) => {

  const { isMobile } = useDeviceDetect();

  const hideWidget = () => {
    window.parent.postMessage({
      closeWidget: true
    }, '*')
  }

  return (
    <StyledFlex
      top={[0, "inherit", "inherit"]}
      width={["100%", "inherit"]}
      position={["fixed", "inherit"]}
      bg={[Colors.blue, Colors.white, Colors.white]}
      pl="15px"
      pt={["8px", "inherit"]}
      pb={["8px", "16px"]}
      mt={["0px", "16px"]}
      pr={["15px", ""]}
      color={[Colors.white, "inherit"]}
      zIndex={["999", "100", "inherit"]}
      borderTopLeftRadius={8}
      borderTopRightRadius={8}
      justifyContent="space-between"
      alignItems="center" >
      <Flex alignItems="center" flex="1">
        {isMobile
          ? (
            <Flex alignItems="center" justifyContent="space-between" flex="1">
              <div>
                {!isWidget && (<Box height="20px" as="img" src={assets.HamburgerMenu} />)}
              </div>
              <Box height="35px">
                <Box height="25px" as="img" src={headerImage ? headerImage : assets.botLogoWithText} />
              </Box>
              <div>
                {isWidget && (<div style={{ cursor: 'pointer' }} onClick={hideWidget}><Box height="20px" as="img" src={assets.CloseBtn} /></div>)}
              </div>
            </Flex>
          )
          : (
            <>
              <Box ml={"8px"}>
                <LogoWithIndicator
                  logoSize={20}
                  containerSize={40}
                  borderColor={Colors.borderLine}
                  dotColor={Colors.green}
                  indicatorType="dot"
                />
              </Box>
              <div style={{ marginLeft: 10 }}>
                <Bot>Bot MD</Bot>
                <OnlineStatus>
                  Online
                </OnlineStatus>
              </div>
            </>
          )}
      </Flex>

    </StyledFlex>
  );
})

export default Header;
