import { useEffect } from 'react';
import { Box } from '@fivehealth/botero';
import { APP_INFO } from '../../constants/variables';
import { Colors } from '../../constants/colors';
import { useCookies } from 'react-cookie';

interface ILoginProps {
  url: string
}

const Login = (props: ILoginProps) => {
  const [cookies, setCookie] = useCookies([APP_INFO.cookieName]);

  useEffect(() => {
    window.addEventListener('message', message => {
      const iframe = window.document.getElementById('login-iframe')?.contentWindow;
      if (message.source !== iframe) {
        return;
      } else {
        setCookie(APP_INFO.cookieName, {
          ...(cookies[APP_INFO.cookieName] || {}),
          session: message.data.session
        }, { path: '/' });
        window.document.location.reload()
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      display="flex"
      mt={[0, "-100px"]}
      height={["100vh", "82vh", "82vh"]}>
      <Box
        margin="0 auto"
        width={["100%", "600px"]}
      >
        <iframe
          scrolling="no"
          title="login-iframe"
          id="login-iframe"
          frameBorder="0"
          style={{
            background: Colors.white,
            borderRadius: 32,
          }}
          width="100%"
          height="100%"
          src={props.url} />

      </Box>
    </Box>
  );
};

export default Login;
