import styled from 'styled-components';
import './styles.css';
import { Colors } from '../../constants/colors';
import { parseTemplateString } from '../../utils/appUtils';
import { Text, Box } from '@fivehealth/botero';
import { PhoneNumberRegex } from '../../constants/variables';
import Linkify from 'linkifyjs/react';

interface IChatMessageText {
  message: any;
  variables: any;
  numberOfButtons?: number;
  isSection?: boolean;
}

const PhoneNumber = styled.a`
  color: ${Colors.blue};
  text-decoration-line: none;
`

const ResponsiveText = styled(Box)`
  font-family: 'Inter';
  &[type] {
    padding: 0px 10px 0px 10px !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
  }

  &[type="title"] {
    margin-bottom: 10px;
    -webkit-line-clamp: 3;
    font-weight: 500;
    height: auto;
    text-overflow: ellipsis;
  }

  &[type="subtitle"] {
    -webkit-line-clamp: 3;
    max-height: auto;
    font-weight: 400;
    text-overflow: ellipsis;
  }

  &[type="text"] {
    padding-top: 2px;
    -webkit-line-clamp: 16;
  }

  &[class~="action-btn-1"] {
    -webkit-line-clamp: 5;
    height: 80px;
    text-overflow: ellipsis;
  }

  &[class~="action-btn-1-section"] {
    height: auto;
  }

  &[class~="action-btn-2"] {
    -webkit-line-clamp: 4;
    height: 67px;
    text-overflow: ellipsis;
  }

  &[class~="action-btn-3"] {
    -webkit-line-clamp: 2;
    height: 30px;
    text-overflow: ellipsis;
  }
`

const ChatMessageText = (props: IChatMessageText) => {

  const { message, variables, numberOfButtons } = props;
  const { type } = message;

  const linkProps = {
    target: "_blank",
    className: 'link'
  };

  const text = message[type]
  const content = parseTemplateString(text, variables || {})

  const renderTextWithPhoneNumber = (content: string) => {
    const parsed = content.split(PhoneNumberRegex);
    return parsed.map((str: string) => {
      return PhoneNumberRegex.test(str) ? <PhoneNumber key={str} href={`tel: ${str}`}>{str}</PhoneNumber> : str
    })
  }

  const textProps: any = {
    title: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px'
    },
    subtitle: {
      fontSize: 14,
      fontWeight: 500,
      mb: 2,
      flex: 1
    },
    text: {
      fontSize: 12,
      lineHeight: '16px',
      mb: 1,
      numberOfButtons,
      flex: 1
    }

  }

  return (
    <Text
      title={content}
      {...textProps[type]}
      className={type === "text" && `action-btn-${numberOfButtons}`}>
      <Linkify options={{ attributes: linkProps }}>
        <ResponsiveText type={type} className={type === "text" && `action-btn-${numberOfButtons}${!props.isSection && "-section"}`}>
          {PhoneNumberRegex.test(content) ? renderTextWithPhoneNumber(content) : content}
        </ResponsiveText>
      </Linkify>
    </Text>
  )

};

export default ChatMessageText;
