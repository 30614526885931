import { Box, theme } from '@fivehealth/botero';
import { assets } from '../../assets/images';
import styled from 'styled-components';
import { Colors } from '../../constants/colors';

interface ILogoWithIndicator {
  indicatorType?: "dot" | "image"
  backgroundColor?: string
  borderColor?: string
  containerSize?: number
  containerPadding?: number
  logoSize?: number
  imageUrl?: string
  imageSize?: number
  dotColor?: string
  dotSize?: number
  indicatorStyles?: Object
}

const Container: any = styled(Box)`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: ${(props: any) => props.containerPadding || 0}px;
  background-color: ${(props: any) => props.background || 'transparent'};
`

const DotIndicator: any = styled.div`
  position: absolute;
  width: ${(props: any) => props.dotSize || 10}px;
  height: ${(props: any) => props.dotSize || 10}px;
  background-color: ${(props: any) => props.dotColor || 'red'};
  border-radius: 50%;
  margin-top: 25px;
  margin-left: 35px;
  border: 1px solid ${Colors.white};
  ${(props: any) => props.indicatorStyles && { ...props.indicatorStyles }}
`

const ImageIndicator: any = styled.div`
  position: absolute;
  max-width: 32px;
  max-height: 32px;
  width: ${(props: any) => props.imageSize || 32}px;
  height: ${(props: any) => props.imageSize || 32}px;
  border-radius: 50%;
  margin-top: 25px;
  margin-left: 35px;
  background: url(${(props: any) => props.imageUrl}) no-repeat;
  background-size: contain;
  border: 3px solid ${theme.colors.lightestShade};
  ${(props: any) => props.indicatorStyles && { ...props.indicatorStyles }}
`

const LogoWithIndicator = (props: ILogoWithIndicator) => {
  return (
    <Container
      border={["", `1px solid ${props.borderColor}`]}
      width={["24px", `${props.containerSize || 40}px`]}
      height={["24px", `${props.containerSize || 40}px`]}
      containerPadding={props.containerPadding}
      containerSize={props.containerSize}
      background={props.backgroundColor}
      borderColor={props.borderColor}
    >
      <Box
        position="relative"
        width={["18px", `${props.logoSize || 20}px`]}
        as="img"
        src={assets.botLogo}
      />
      {props.indicatorType === "dot" && (
        <DotIndicator
          dotColor={props.dotColor}
          dotSize={props.dotSize}
          indicatorStyles={props.indicatorStyles}
        />
      )}
      {props.indicatorType === "image" && (
        <ImageIndicator
          imageSize={props.imageSize}
          imageUrl={props.imageUrl}
          indicatorStyles={props.indicatorStyles}
        />
      )}
    </Container>
  );
};

export default LogoWithIndicator;
