import { v4 as uuidv4 } from "uuid";
import {
    APP_INFO,
    ORG_WITH_PUBLIC_SESSION,
    PhoneNumberRegex,
} from "../constants/variables";
import { DateTime } from "luxon";
import { sortMessages } from "./sortMessagesUtil";
import { get } from "lodash";
import { GRAPHQL_DOCUMENT_MESSAGES } from "../api/queries/useMessages";

export const parseTemplateString = (str: string, interpolations: any) => {
    if (!str) return "";
    return str
        .toString()
        .replace(/\$\{\s*([^}\s]+)\s*\}/g, (_, token) => interpolations[token]);
};

export const generateChatMessageId = () => {
    return uuidv4().split("-").join("");
};

export const extractPhoneNumbersFromText = (text: string) => {
    return text
        .split(PhoneNumberRegex)
        .filter((str) => PhoneNumberRegex.test(str));
};

export const getSortMethod = function (...args: string[]) {
    let _args = Array.prototype.slice.call(arguments);
    return function (a: any, b: any) {
        for (let x in _args) {
            let ax = a[_args[x].substring(1)];
            let bx = b[_args[x].substring(1)];
            let cx;

            ax = typeof ax === "string" ? ax.toLowerCase() : ax / 1;
            bx = typeof bx === "string" ? bx.toLowerCase() : bx / 1;

            if (_args[x].substring(0, 1) === "-") {
                cx = ax;
                ax = bx;
                bx = cx;
            }
            if (ax !== bx) {
                return ax < bx ? -1 : 1;
            }
        }
    };
};

export const getMessageTimeStamp = (date: string) => {
    const currentTime = DateTime.local();
    const messageTime = DateTime.fromISO(date);

    if (
        `${currentTime.day}-${currentTime.hour}-${currentTime.second}` ===
        `${messageTime.day}-${messageTime.hour}-${messageTime.second}`
    ) {
        return "Just now";
    }

    if (
        `${currentTime.day}-${currentTime.hour}` ===
        `${messageTime.day}-${messageTime.hour}`
    ) {
        const differenceInSeconds = currentTime.second - messageTime.second;
        return currentTime.minute - messageTime.minute === 0
            ? `${
                  differenceInSeconds > 0
                      ? differenceInSeconds
                      : differenceInSeconds * -1
              }s ago`
            : `${currentTime.minute - messageTime.minute}m ago`;
    }

    return messageTime.toFormat("hh:mm a");
};

export const formatMessageDate = (date: string) => {
    return `${DateTime.fromISO(date).toFormat("DD")}, ${DateTime.fromISO(date)
        .toFormat("hh:mm a")
        .toLocaleLowerCase()}`;
};

export const getPDFViewerDomain = () => {
    const STAGING = "viewer.staging.botmd.io";
    const PROD = "viewer.botmd.io";

   return isLocalOrStaging() ? STAGING : PROD;
};

export const updateMessagesQueryData = (
    queryClient: any,
    messages: Array<any>,
    concatOldData: boolean = true
) => {
    queryClient.setQueryData(["messages", {}], (oldVal: any) => {
        let previousMessages: any = [];

        if (Array.isArray(oldVal)) {
            previousMessages = oldVal;
        }

        if (get(oldVal, "hospitalMessages.edges")) {
            previousMessages = get(oldVal, "hospitalMessages.edges");
        }

        return sortMessages(messages, concatOldData ? previousMessages : []);
    });
};
export const displayMobileView = () => window.innerWidth <= 575.98;

export const getLoggedInTime = () => {
    if (sessionStorage.getItem(APP_INFO.loggedInUserTimestamp)) {
        return sessionStorage.getItem(APP_INFO.loggedInUserTimestamp);
    }
    const currentTime = DateTime.now().minus({ minute: 1 }).toString();
    sessionStorage.setItem(APP_INFO.loggedInUserTimestamp, currentTime);
    return currentTime;
};

export const generateChannelUid = (cookies: any, setCookie: any) => {
    if (cookies && cookies[APP_INFO.cookieName]?.channelUid) {
        return cookies[APP_INFO.cookieName]?.channelUid;
    }

    const channelUid = `private-${generateChatMessageId()}`;
    setCookie(
        APP_INFO.cookieName,
        {
            ...(cookies[APP_INFO.cookieName] || {}),
            channelUid,
        },
        { path: "/" }
    );
    return channelUid;
};

export const updateStitchV2ToStitchV3 = (authState: any, url: string) => {
    if (
        (url.includes("stitch-v2") ||
            url.includes("stitch-v3") ||
            url.includes("t.botmd.io")) &&
        !url.includes("?")
    ) {
        return `${url}?session_uid=${authState.token}`.replace(
            "stitch-v2",
            "stitch-v3"
        );
    }

    if (
        (url.includes("stitch-v2") ||
            url.includes("stitch-v3") ||
            url.includes("t.botmd.io")) &&
        url.includes("?")
    ) {
        return `${url}&session_uid=${authState.token}`.replace(
            "stitch-v2",
            "stitch-v3"
        );
    }

    /* eslint no-template-curly-in-string: 0 */
    return url.replace("${session}", authState.token);
};

export const isWidget = !!new URLSearchParams(document.location.search).get(
    "widget"
);

export const fetchAllMessages = async (queryClient: any, client: any) => {
    const data = await queryClient.fetchQuery(["messages"], () =>
        client.request(GRAPHQL_DOCUMENT_MESSAGES)
    );
    if (data.hospitalMessages.edges) {
        updateMessagesQueryData(
            queryClient,
            data.hospitalMessages.edges,
            false
        );
    }
};

export const isLocalOrStaging = () => {
    // eslint-disable-next-line no-restricted-globals
    const result = location.hostname.match(/staging|localhost|ngrok/);
    return result && result.length > 0 ? true : false;
};

export const getOrganisation = () => {
    const STORAGE_KEY = "botmd_org";
    const org = new URLSearchParams(document.location.search).get("org");
    const local = sessionStorage.getItem(STORAGE_KEY) || "";

    if (org && org !== local) {
        sessionStorage.setItem(STORAGE_KEY, org);
        return org;
    }

    return local;
};

export const getOrgHeaderImg = () => {
    const STORAGE_KEY = "botmd:org:headerImg";
    const headerImg = new URLSearchParams(document.location.search).get("headerImg");
    const local = localStorage.getItem(STORAGE_KEY) || "";

    if (headerImg && headerImg !== local) {
        localStorage.setItem(STORAGE_KEY, headerImg);
        return headerImg;
    }

    return local;
};

export const getInputState = () => {
    const hiddenInput = new URLSearchParams(document.location.search).get("hideInput");
    return hiddenInput;
}

export const hasPublicSession = (org: string) => {
    return ORG_WITH_PUBLIC_SESSION.indexOf(org) > -1;
};

export const isIOS = () => {
    return (
        ["iPad", "iPhone", "iPod"].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        navigator.userAgent.includes("Mac")
    );
};
