import styled from "styled-components";
import { assets } from '../../assets/images';
import { Box, Flex, Body } from '@fivehealth/botero';
import "./styles.css"
import { Colors } from "../../constants/colors";
import { getOrganisation, isWidget } from "../../utils/appUtils";
import { PUBLIC_PROVIDERS } from "../../constants/variables";

interface IChatInput {
  onChange: (e: any) => void
  onSubmit: (e: any) => void
  onHomePressed: () => void
  value: string
  isWidget?: boolean
  showInputBox?: boolean
}

const InnerContainer = styled.form`
  display: flex;
  input {
    background: #fff none repeat scroll 0 0;
    color: #000;
    font-size: 15px;
    width: 100%;
    padding-left: 10px;
    border: 1px solid ${Colors.lightGray};
    box-sizing: border-box;
    border-radius: 32px;
    height: 44px;
    outline: none;
    padding-right: 10%;
    @media (max-width: 575.98px) {
      padding-right: 17%;
    }
  }
  input:focus {
    border: 1px solid ${Colors.lightGray};
  }

  input::-webkit-input-placeholder {
    color: ${Colors.lightGray};
  }

  input:-ms-input-placeholder {
    color: ${Colors.lightGray};
  }

  input::placeholder {
    color: ${Colors.lightGray};
  }
`

const RightContent = styled(Box)`
  img {
    height: 42px;
    width: 42px;
  }
`

const StyledContainer = styled(Box)`
  visibility: ${(props: any) => props.visibility};
`

const TextInputContainer = styled(Box)`
  width: 100%;
  float: right;
`

const SendBtnContainer = styled(Box)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
`

const org = getOrganisation()

const ChatInput = (props: IChatInput) => {

  const hideChatInput = () => {
    const provider = PUBLIC_PROVIDERS[org];
    return provider ? isWidget && provider.hideInput : false;
  }

  return (
    <Flex flexDirection="column">
      <StyledContainer
        showInputBox={props?.showInputBox}
        display={hideChatInput() && !props?.showInputBox ? 'none' : 'block'}
        mt={"2px"}
        borderRadius={["", "0 0 16px 0"]}
        width="100%"
        bottom={[0]}
        bg={["white"]}
        zIndex={[3]}
        pb={["0px", "10px"]}
        position="relative">
        <InnerContainer onSubmit={props.onSubmit}>
          <Box
            width={["50px", "65px"]}
            mr={['5px', 0]}
            display="inline-block">
            <Box
              cursor="pointer"
              onClick={props.onHomePressed}
              pl={["5px", "10px"]}
              display="inline-block">
              <img alt="" src={assets.homeBtn} style={{ height: 42, width: 42 }} />
            </Box>
          </Box>
          <RightContent
            width={["calc(100% - 70px)", "calc(100% - 100px)"]}
            display="inline-block">
            <TextInputContainer>
              <input type="text"
                onChange={props.onChange}
                className="write_msg"
                value={props.value}
                placeholder="  Type a message"
              />
            </TextInputContainer>
            <Box
              top="12px"
              position="absolute"
              right={["30px", "55px"]}
              onClick={props.onSubmit}>
              <SendBtnContainer color={props.value ? Colors.blue : Colors.lightGray}>Send</SendBtnContainer>
            </Box>
          </RightContent>
        </InnerContainer>
      </StyledContainer>
      {props?.isWidget && (
        <div style={{
          margin: 'auto',
          paddingTop: !!props.showInputBox ? '0px' : '15px',
        }}>
          <div
            onClick={() => window.open('https://botmd.io', '_blank')}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",

            }}>
            <Body small mr="2px" fontSize="14px">
              Powered by
            </Body>
            <img alt="" src="https://t.botmd.io/botmd_logo" style={{ height: 30 }} />
          </div>
        </div>)}
    </Flex>

  );
};

export default ChatInput;
