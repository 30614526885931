import {
  faStar,
  // faThermometerThreeQuarters,
  faBell,
  faInfoCircle,
  faCommentDots
} from '@fortawesome/pro-regular-svg-icons';

const menu = [
  {
    id: 0,
    name: 'Chat',
    icon: faCommentDots,
    path: '/',
    requireLogin: false,
    show: true
  },
  {
    id: 1,
    name: 'Favorites',
    icon: faStar,
    path: '/favorites',
    marginLeft: "-3px",
    requireLogin: true,
    show: true
  },
  // {
  //   id: 2,
  //   name: 'Temperature module',
  //   icon: faThermometerThreeQuarters,
  //   path: '/temperature',
  //   marginLeft: "2px"
  // },
  {
    id: 3,
    name: 'Help guide',
    icon: faInfoCircle,
    path: '/#',
    marginLeft: "-2px",
    onClick: () => window.open('https://help.botmd.com', '_blank'),
    requireLogin: false,
    show: true
  },
  {
    id: 4,
    name: 'Notifications',
    icon: faBell,
    path: '/notifications',
    marginLeft: "-1px",
    requireLogin: false,
    show: false
  },
  
]
export default menu;
