import React, { useRef } from "react";
import ActionButton from "../ActionButton";

interface IMediaPlayerSection {
  mediaSection: any;
  mediaSource: any;
  variables: any;
  renderItem: (
    message: any,
    nodeType: string,
    variables: any,
    key: any,
    isSection: boolean
  ) => React.ReactNode;
  onPlay: (mediaPlayerRef: any) => void;
}

const MediaPlayerSection = (props: IMediaPlayerSection) => {
  const mediaPlayerRef = useRef<any>(null);
  const { mediaSection, mediaSource, variables, renderItem, onPlay } = props;

  let paragraphSourceElement: React.ReactNode = "";
  let mediaTitle = "";
  const newVariables = {
    ...(variables || {}),
    mediaPlayerRef,
  };

  const mediaSourceElement: React.ReactNode = renderItem(
    mediaSource,
    mediaSource.type,
    newVariables,
    null,
    true
  );

  const paragraphSource = mediaSection.find(
    (section: any) => section.type === "paragraphs"
  );

  if (paragraphSource) {
    mediaTitle = paragraphSource.paragraphs[0].title || "";
    paragraphSourceElement = renderItem(
      paragraphSource,
      paragraphSource.type,
      variables,
      null,
      true
    );
  }

  const playMediaElement = (
    <ActionButton
      content={{
        content_type: "button",
        url: "",
        text: `Play ${mediaSource.type}`,
      }}
      onPress={() => {
        if (mediaPlayerRef.current) {
          mediaPlayerRef.current.mediaTitle = mediaTitle;
          mediaPlayerRef.current.mediaType = mediaSource.type;
          onPlay(mediaPlayerRef);
        }
      }}
    />
  );

  if (!mediaSource[mediaSource.type].url) {
    return <>{renderItem({ type: "text" }, "text", variables, null, true)}</>;
  }

  return (
    <>
      {mediaSourceElement}
      {paragraphSourceElement}
      {playMediaElement}
    </>
  );
};

export default MediaPlayerSection;
