/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { memo } from 'react';
import ReactPlayer from "react-player";
import { Box, Flex } from "@fivehealth/botero";
import styled from "styled-components";
import { assets } from "./../../assets/images";

const PlayerWrapper = styled(Box)`
    cursor: default;
    & video {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    & .react-player__play-icon,
    & .react-player__shadow {
        display: none !important;
    }
    z-index: 1;
    width: 100%;
    height: 100%;
    position: relative;
`;

type MediaMessage = {
  type: "audio" | "video";
  audio?: {
    url: string;
  };
  video?: {
    url: string;
  };
};

interface IMediaPlayer {
  variables: any;
  message: MediaMessage;
  onClick: (mediaPlayerRef: any) => void;
}

const MediaPlayer = ({ message, variables, onClick }: IMediaPlayer) => {
  const getMediaUrl = () => {
    return message[message.type]?.url || "";
  };

  const mediaUrl = getMediaUrl();
  const mediaPlayerRef = (variables || {}).mediaPlayerRef;

  return (
    <Box style={{ background: "#fff" }} mt="-5px">
      <Flex flexDirection="column">
        {!!mediaUrl && (
          <PlayerWrapper>
            <ReactPlayer
              ref={mediaPlayerRef}
              url={mediaUrl}
              width="100%"
              height="100%"
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                cursor: "pointer",
                overflow: 'hidden',
              }}
              light={
                message && message.type === "audio" ? (
                  <img
                    src={assets.audioPlayback}
                    alt="Thumbnail"
                    height="136"
                    style={{
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      cursor: 'pointer',
                    }}
                  />
                ) : undefined
              }
            />
            <a
              style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 2,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                onClick(mediaPlayerRef);
              }}
            />
          </PlayerWrapper>
        )}
      </Flex>
    </Box>
  );
};

export default memo(MediaPlayer);
