import { useEffect, useState, useRef, useMemo } from "react";
import { isEmpty, } from "lodash";
import { useQueryClient } from "react-query";
import ReactPlayer from "react-player";

import { getDateTimeNow } from "../../utils/formatISODateTime";
import { useApi, Box, useAuth, parseMessageJSON, Flex, Text } from "@fivehealth/botero";

import "./styles.css";
import { fetchAllMessages, formatMessageDate, generateChatMessageId, getOrganisation, getInputState, hasPublicSession, isIOS, isWidget, parseTemplateString, updateMessagesQueryData } from "../../utils/appUtils";
import { assets } from "../../assets/images";
import ChatBubble from "../../components/ChatBubble";
import ChatMessageText from "../../components/ChatMessageText";
import ChatInput from "../../components/ChatInput";
import QuickReplies from "../../components/QuickReplies";
import ActionButton from "../../components/ActionButton";
import ChatImage from "../../components/ChatImage";
import styled from "styled-components";
import ChatCarousel from "../../components/ChatCarousel";
import { useAppData } from "../../context/AppDataContext";
import { Colors } from "../../constants/colors";
import BotTypingIndicator from "../../components/BotTypingIndicator";
import { RocheWelcomeMessage, WelcomeMessage, SessionExpiredMessage } from "../../constants/welcomeMessage";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { APP_INFO, PUBLIC_PROVIDERS } from "../../constants/variables";
import { useCookies } from "react-cookie";
import MediaPlayer from "../../components/MediaPlayer/MediaPlayer";
import MediaPlayerSection from "../../components/MediaPlayer/MediaPlayerSection";

const MessagesHistory = styled(Box)`
    overflow-y: auto;
    flex-direction: column;
    /* need if I need to display initial message lower */
    // display: flex;
`;

const MessageDate = styled(Box)`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.lightGray};
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
`;

let typingIndicatorInterval: any = undefined;

const Chats = () => {
    const org = getOrganisation();
    const hideInput = getInputState();
    const messageRef = useRef<any>();
    const loadedRef = useRef<boolean>(false);
    const dateHeaderRef = useRef<string>("");
    const { authState, logout, login } = useAuth();
    const { state } = useAppData();
    const { client } = useApi();
    const [inputMsg, setInputMsg] = useState<string>("");
    const [showTypingIndicator, setShowTypingIndicator] = useState(false);
    const [previewMode, setPreviewMode] = useState(false);

    const hideChatInput = () => {
        const provider = PUBLIC_PROVIDERS[org];
        return provider ? isWidget && provider.hideInput : false;
    }

    const [showInputBox, setShowInputBox] = useState<boolean>(true);

    const [currentMediaPlayerRef, setCurrentMediaPlayerRef] = useState<any>(null);

    const initialWelcomeMessage = {
        node: org === "roche" ? RocheWelcomeMessage : WelcomeMessage,
    };

    const queryClient = useQueryClient();

    const {
        queries: { useMessages, usePostMessage, useHospitalJarvisQuery },
    } = useApi({
        queries: ["useMessages", "usePostMessage", "useHospitalJarvisQuery"],
    });

    const { data, error } = useMessages({
        enabled: authState.authenticated,
    });

    const [messagesData, setMessagesData] = useState<any>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [initialMessageLoaded, setInitialMessageLoaded] = useState(false);

    const { openModal, openMobileLoginModal, resetUser } = useAppData();
    const { isMobile } = useDeviceDetect();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie] = useCookies([APP_INFO.cookieName]);

    const { mutate } = usePostMessage({
        variables: {},
    });

    useEffect(() => {
        if (data) {
            setMessagesData(data);
        }
    }, [data]);

    const { mutateAsync: postJarvisQuery } = useHospitalJarvisQuery({
        enabled: authState.authenticated,
        variables: {},
        onSuccess: ({ data }: any) => {
            if (!previewMode) {
                fetchMessages();
            }
        },
        onError: () => {
            logout();
        },
    });

    const scrollToTop = () => {
        if (messageRef.current) {
            messageRef.current.scrollTop = messageRef.current.scrollHeight;
        }
    };

    const loginUser = (token: string) => {
        sessionStorage.setItem(APP_INFO.cookieName, token);
        setCookie(
            APP_INFO.cookieName,
            {
                ...(cookies[APP_INFO.cookieName] || {}),
                session: token,
            },
            { path: "/" }
        );
        login({ token });
    };

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data && event.data.session) {
                loginUser(event.data.session);
            }
            if (event.data && event.data.logout) {
                logout();
            }
            if (event.data && event.data.externalSession) {
                loginUser(event.data.externalSession);
            }

            if (event.data && event.data.previewMode) {
                setPreviewMode(true);
            }

            if (event.data && Array.isArray(event.data)) {
                // setMessagesData((messages:any) => (messages.push(event.data)))
                if (Array.isArray(event.data[0])) {
                    setMessagesData([...messagesData, ...event.data[0]]);
                }

                // setMessagesData([...messagesData, event.data])
                //setMessagesData(messagesData.push(event.data.node))
                //renderMessage(event.data, data.length+1)
            }
        });

        window.parent.postMessage(
            {
                listener: true,
            },
            "*"
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const stopWidgetLoadingIndicator = () => {
        if (!loadedRef.current) {
            setInitialMessageLoaded(true);
            loadedRef.current = true;
            window.parent.postMessage(
                {
                    stopLoadingIndicator: true,
                },
                "*"
            );
        }
    };

    useEffect(() => {
        if (messagesData && messagesData.length > 0 && authState.authenticated) {
            setShowTypingIndicator(messagesData[messagesData.length - 1].node.direction === "incoming");
            setTimeout(() => {
                scrollToTop();
            });
            if (!loadedRef.current) {
                stopWidgetLoadingIndicator();
            }
        }

        if (messagesData?.length === 0 && authState.authenticated && loadedRef.current && showInputBox && hasPublicSession(org)) {
            setShowInputBox(false);
        } else {
            if (hideInput === "true") {
                setShowInputBox(false);
            } else {
                if (hideChatInput()) {
                    setShowInputBox(false);
                } else {
                    setShowInputBox(true);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messagesData]);

    useEffect(() => {
        if (messagesData?.length > 0 && authState.authenticated && !loadedRef.current) {
            stopWidgetLoadingIndicator();
        }

        if (!authState.authenticated && !hasPublicSession(org) && !loadedRef.current) {
            stopWidgetLoadingIndicator();
        }
        /* eslint react-hooks/exhaustive-deps: 0 */
    }, [authState]);

    useEffect(() => {
        if (authState.authenticated && error && !previewMode) {
            logout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (showTypingIndicator) {
            scrollToTop();
            typingIndicatorInterval = setTimeout(async () => {
                if (showTypingIndicator) {
                    fetchMessages();
                    clearTimeout(typingIndicatorInterval);
                }
            }, 3000);
        } else {
            clearTimeout(typingIndicatorInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTypingIndicator]);

    const handleInputChange = (val: string) => {
        setInputMsg(val);
    };

    const fetchMessages = async () => {
        try {
            await fetchAllMessages(queryClient, client);
        } catch (error) {
            logout();
        }
    };

    const sendMessage = (e: any, isQuickReply: boolean = false, url: string = "") => {
        if (!isQuickReply) {
            e.preventDefault();
        }

        if (!isEmpty(isQuickReply ? e : inputMsg)) {
            const newMessage: any = {
                messageContent: {
                    type: "message",
                    message: {
                        type: "text",
                        text: isQuickReply ? e : inputMsg,
                    },
                },
            };

            if (isWidget) {
                window.parent.postMessage({ beforeSend: newMessage }, "*");
            }

            if (url && url.includes("jarvisQuery")) {
                const uid = decodeURIComponent(url.split("uid=")[1]);
                postJarvisQuery({
                    input: {
                        uid,
                        createDelivery: {
                            uid: generateChatMessageId(),
                            messageContent: {
                                type: "message",
                                message: {
                                    type: "text",
                                    text: e,
                                },
                            },
                        },
                    },
                });
                return;
            } else {
                mutate({
                    input: {
                        ...newMessage,
                    },
                });
            }
            newMessage.direction = "incoming";
            newMessage.createdOn = getDateTimeNow();
            newMessage.uid = generateChatMessageId();

            setInputMsg("");

            updateMessagesQueryData(queryClient, [{ node: { ...newMessage } }]);
        }
    };

    const onActionButtonPressed = (content: any) => {
        let { url, content_type, external_browser, text } = content;

        if (text === "SMS Login" || text === "QR Code Login") {
            if (isMobile) {
                openMobileLoginModal(url);
                return;
            }
            window.open(url, "_top");
            return;
        }

        if (external_browser) {
            window.open(url, "_blank");
            return;
        }

        if (url.includes("botmd:")) {
            const text = unescape(decodeURI(url.split("?text=")[1]))
                .replaceAll("+", " ")
                .trim();
            if (text === "undefined") {
                alert("Module not implemented yet.");
                return;
            }

            setTimeout(() => {
                sendMessage(text, true, url);
            }, 300);
            return;
        }

        if (url.includes("login.")) {
            url = `${url}&&redirectTo=${APP_INFO.redirectUri}`;

            if (isWidget && isIOS()) {
                const windowRef = window.open(url, "popUpWindow", "height=600,width=600,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes");

                if (windowRef) {
                    const appleDevicesPopupInterval = setInterval(() => {
                        try {
                            if (windowRef?.location.href.includes("session=")) {
                                const token = windowRef?.location.href.split("session=")[1];
                                if (token === "null&reason=No+valid+profile+found+for+identifiable+information.") {
                                    windowRef?.close();
                                    if (org === "roche") {
                                        const redirectUri = APP_INFO.rocheSignUpForm;
                                        openModal({
                                            contentType: "text/html; charset=UTF-8",
                                            url: redirectUri,
                                        });
                                    }
                                    return;
                                }
                                clearInterval(appleDevicesPopupInterval);
                                windowRef?.close();
                                resetUser();
                                loginUser(token);
                            }
                        } catch (error: any) {
                            if (!error.message.includes("frame")) {
                                clearInterval(appleDevicesPopupInterval);
                                windowRef?.close();
                            }
                        }
                    }, 500);
                }
                return;
            }
        }
        openModal({ contentType: content_type, url });
    };

    const getNumOfActionBtn = (message: any) => {
        const list = message.sections.filter((item: any) => item.type === "actions");
        if (list.length > 0) {
            return list[0].actions.length;
        }
        return 0;
    };

    const handleQuickReply = (qr: any) => {
        setShowTypingIndicator(true);
        const { url, text } = qr.quick_reply;
        let msg = text;
        if (url.includes("?text=")) {
            msg = decodeURI(url.split("?text=")[1]).replaceAll("+", " ").trim();
        }
        sendMessage(decodeURIComponent(msg), true, url);
    };

    const renderMessageComponents = (message: any, nodeType?: string, variables?: any, key?: any, isSection: boolean = false, numberOfButtons: number = 0) => {
        const { type } = message;
        switch (type) {
            case "text":
            case "title":
            case "subtitle":
                return <ChatMessageText key={key} message={message} variables={variables} numberOfButtons={numberOfButtons} isSection={isSection} />;
            case "button":
                const content = message.button;
                content.url = parseTemplateString(content.url, variables || {});
                return <ActionButton content={content} onPress={onActionButtonPressed} key={key} />;
            case "image":
                return <ChatImage key={key} authState={authState} position={message.image?.scale} imgUrl={message.image.url} isSection={isSection} />;
            case "sections":
                const mediaSource = message.sections.find((section: any) => ["audio", "video"].includes(section.type));

                if (mediaSource) {
                    return <MediaPlayerSection key={key} mediaSource={mediaSource} mediaSection={message.sections} variables={variables} renderItem={renderMessageComponents} onPlay={(mediaPlayerRef) => setCurrentMediaPlayerRef(mediaPlayerRef)} />;
                }

                const numActionButtons = getNumOfActionBtn(message);
                return message.sections.map((msg: any, i: number) => renderMessageComponents(msg, msg.type, variables, i, true, numActionButtons));
            case "paragraphs":
                return message.paragraphs.map((msg: any, i: number) => renderMessageComponents(msg, msg.type, variables, i, isSection, numberOfButtons));
            case "actions":
                return message.actions.map((msg: any, i: number) => renderMessageComponents(msg, msg.type, variables, i));
            case "quick_replies":
                return <QuickReplies previewMode={previewMode} message={message} onClick={handleQuickReply} onMoreAdded={scrollToTop} />;
            case "carousel":
                if (message.carousel.length === 1) {
                    const mediaSource = message.carousel[0].sections.find((section: any) => ["audio", "video"].includes(section.type));

                    if (mediaSource) {
                        return <MediaPlayerSection key={key} mediaSource={mediaSource} mediaSection={message.carousel[0].sections} variables={variables} renderItem={renderMessageComponents} onPlay={(mediaPlayerRef) => setCurrentMediaPlayerRef(mediaPlayerRef)} />;
                    }

                    const numActionButtons = getNumOfActionBtn(message.carousel[0]);
                    return message.carousel[0].sections.map((msg: any, i: number) => {
                        return renderMessageComponents(msg, "", variables, i, true, numActionButtons);
                    });
                }
                return <ChatCarousel key={key} data={message} variables={variables} renderItem={renderMessageComponents} />;
            case "application_payload":
                return null;
            case "video":
            case "audio":
                return <MediaPlayer variables={variables} message={message} onClick={(newCurrentPlayerRef: any) => setCurrentMediaPlayerRef(newCurrentPlayerRef)} />;
            default:
                return (
                    <ChatMessageText
                        key="text"
                        message={{
                            type: "text",
                            text: "No component found for this query",
                        }}
                        variables={{}}
                        numberOfButtons={0}
                        isSection={false}
                    />
                );
        }
    };

    const hasImage = (sections: Array<any>) => {
        return sections.findIndex((item) => item.type === "image") > -1;
    };

    const renderMessage = ({ node }: any, index: number) => {
        if (isEmpty(node)) return null;

        node = parseMessageJSON(node, {
            session: authState?.token ?? "",
            userUid: state.user?.uid ?? "",
            organization_key: org,
            channelUid: cookies[APP_INFO.cookieName]?.channelUid ?? "",
        });

        const { messageContent } = node;

        if (!messageContent) return null;

        const { message } = messageContent;
        const { type } = message;

        const dateHeader = formatMessageDate(node.createdOn);
        dateHeaderRef.current = index > 1 ? dateHeader : "";

        if (type === "quick_replies" && index < messagesData.length) {
            if (index === 1) {
                return <MessageDate style={{ textAlign: "center" }}>{dateHeader}</MessageDate>;
            }
            return null;
        }

        if (type === "quick_replies") {
            return <QuickReplies previewMode={previewMode} key={node.uid} message={message} onClick={handleQuickReply} onMoreAdded={scrollToTop} />;
        } else {
            let clearBackground = false;
            if (type === "sections") {
                clearBackground = hasImage(message.sections);
            } else if (type === "carousel") {
                clearBackground = true;
            }

            const messageComponents = renderMessageComponents(message, type, node.metadata?.variables, node.uid);

            if (messageComponents) {
                return (
                    <>
                        {dateHeaderRef.current !== dateHeader && <MessageDate style={{ textAlign: "center" }}>{dateHeader}</MessageDate>}
                        <ChatBubble key={node.uid} direction={node.direction} createdOn={node.createdOn} clearBackground={clearBackground} isCarousel={type === "carousel" && message.carousel.length > 1 ? true : false} isLast={authState.authenticated ? messagesData.length === index : true} width={type === "sections" && !clearBackground ? "auto" : undefined} minWidth={["carousel", "sections"].includes(type) ? "200px" : undefined}>
                            {messageComponents}
                        </ChatBubble>
                    </>
                );
            } else {
                return null;
            }
        }
    };

    const messagesContainerStyles = useMemo(() => {
        if (previewMode) {
            return ["calc(100vh - 45px)", "67vh"];
        } else {
            return showInputBox ? ["calc(97vh - 116px)", "67vh"] : ["calc(100vh - 116px)", "67vh"];
        }
    }, [showInputBox, previewMode]);

    return (
        <Box id="chats_container">
            <Box style={{ width: "100%", minHeight: "73vh" }} id="messages">
                {!!currentMediaPlayerRef && currentMediaPlayerRef.current ? (
                    <Box
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            background: "#000",
                            zIndex: "2",
                        }}
                    >
                        <Flex p={2} justifyContent="space-between" alignItems="center">
                            <Text color="#fff">{currentMediaPlayerRef.current.mediaTitle}</Text>
                            <div style={{ cursor: "pointer" }} onClick={() => setCurrentMediaPlayerRef(null)}>
                                <Box height="14px" as="img" src={assets.CloseBtn} />
                            </div>
                        </Flex>
                        <ReactPlayer
                            className="react-player"
                            url={currentMediaPlayerRef.current.props.url}
                            width="100%"
                            height="50%"
                            style={{ position: "absolute", top: "22%" }}
                            controls
                            playing={true}
                            config={{
                                youtube: {
                                    playerVars: {
                                        autoplay: 1,
                                        rel: 0,
                                        showinfo: 1,
                                        loop: 1,
                                    }
                                }
                            }}
                        />
                    </Box>
                ) : null}

                <MessagesHistory mt={["50px", "inherit"]} height={messagesContainerStyles} ref={(ref: any) => (messageRef.current = ref)} id="msg_history">
                    {(authState.authenticated || previewMode) && loadedRef.current && (messagesData ?? []).map((message: any, i: number) => renderMessage(message, i + 1))}

                    {!authState.authenticated && loadedRef.current && !previewMode && [initialWelcomeMessage].map((message: any, i: number) => renderMessage(message, i + 1))}

                    {messagesData && messagesData.length === 0 && (authState.authenticated || previewMode) && loadedRef.current && [SessionExpiredMessage].map((message: any, i: number) => renderMessage(message, i + 1))}

                    {showTypingIndicator && <BotTypingIndicator />}
                </MessagesHistory>
                <ChatInput
                    showInputBox={showInputBox}
                    isWidget={isWidget}
                    onHomePressed={() => {
                        setTimeout(() => {
                            sendMessage("Welcome menu", true);
                        });
                    }}
                    onChange={(e: any) => handleInputChange(e.target.value)}
                    onSubmit={sendMessage}
                    value={inputMsg}
                />
                
            </Box>
        </Box>
    );
};

export default Chats;
