import { useState, useEffect } from 'react';
import styled from 'styled-components';
import QuickReplyText from '../QuickReplyText';
import { Box } from '@fivehealth/botero';

interface IQuickReplies {
    message: any;
    previewMode: boolean;
    onClick: (text: string, isQuickReply: boolean) => void;
    onMoreAdded: () => void;
}


const QuickReplyContainer = styled(Box)`
    flex: 1;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    float: right;
    justify-content: flex-end;
    flex-direction: row;
`

const QuickReplyInnerContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
`

const QUICK_REPLY_PAGE_SIZE = 5;

const QuickReplies = (props: IQuickReplies) => {
    const { message, previewMode } = props
    const [hasMore, setHasMore] = useState(message.quick_replies.length >= QUICK_REPLY_PAGE_SIZE)
    const [seeMoreIndex, setSeeMoreIndex] = useState(1)
    const [quickReplies, setQuickReplies] = useState(message.quick_replies.slice(0, (QUICK_REPLY_PAGE_SIZE - 1) * seeMoreIndex))


    useEffect(() => {
        if (seeMoreIndex > 1) {
            const index = (QUICK_REPLY_PAGE_SIZE - 1) * seeMoreIndex;
            setQuickReplies(message.quick_replies.slice(0, index));
            setTimeout(() => {
                props.onMoreAdded();
            }, 300);
            if (index >= message.quick_replies.length) {
                setHasMore(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seeMoreIndex])

    const showMore = () => {
        if (message.quick_replies.length > (QUICK_REPLY_PAGE_SIZE - 1) * seeMoreIndex) {
            setSeeMoreIndex(seeMoreIndex + 1);
        }
    };

    if (quickReplies) {
        return (
            <QuickReplyContainer pb={["0px", "15px"]} mt={-15} mb="15px" width={["90%", "60%"]}>
                <QuickReplyInnerContainer pr={["10px", "40px"]}>
                    {quickReplies.map((qr: any, i: number) => (
                        <QuickReplyText
                            key={i}
                            text={qr.quick_reply.text}
                            previewMode={previewMode}
                            onClick={() => {
                                props.onClick(qr, true);
                            }}
                        />
                    ))}
                    {hasMore && <QuickReplyText previewMode={previewMode} key={quickReplies.length} text="Show more+" onClick={showMore} />}
                </QuickReplyInnerContainer>
            </QuickReplyContainer>
        );
    }
    return null
};

export default QuickReplies;
