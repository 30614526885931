import { gql } from "graphql-request";
import { get } from "lodash";
import { sortMessages } from "../../utils/sortMessagesUtil";
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_MESSAGES = gql`
  query hospitalMessages {
    hospitalMessages {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          uid
          direction
          messageContent
          metadata
          createdOn
        }
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_MESSAGES,
  queryType: "query",
  baseQueryKey: "messages",
  keepPreviousData: true,
  select: (data) => {
    // this function gets called whenever anything on the ["messages", {}] useQuery is called because the application is listening to the 'data' inside of it, hence the need for the if-else statement.
    if (!get(data, "hospitalMessages.edges") && Array.isArray(data)) {
      // checks if this is the first time fetching, if it is not - it will just return the data as is because it is already formatted.
      return sortMessages(data, []);
    } else {
      // else, format the data from hospitalMessages.edges
      return sortMessages(get(data, "hospitalMessages.edges", []), [])
    }
  },
});
