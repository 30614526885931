import React, {
    createContext,
    useContext,
    useState,
    useMemo,
    useCallback,
} from "react";
import { LOGIN_URL } from "../constants/variables";
import { IUser } from "../types";

interface IProps {
    children?: React.ReactNode;
}

interface IState {
    state: {
        user?: any;
    };
    modal: IModalContent,
    resetModal: Function,
    openModal: Function,
    updateUser: Function;
    resetUser: Function;
    showMobileLoginModal: boolean;
    mobileLoginOption: string;
    closeMobileLoginModal: () => void;
    openMobileLoginModal: (url: string) => void;
}

export interface IModalContent {
    url: string;
    contentType: string;
}

const initialState: IState = {
    state: {
        user: null,
    },
    modal: {
        contentType: '',
        url: ''
    },
    resetModal: () => { },
    openModal: (content: IModalContent) => { },
    updateUser: () => { },
    resetUser: () => { },
    showMobileLoginModal: false,
    mobileLoginOption: LOGIN_URL,
    closeMobileLoginModal: () => { },
    openMobileLoginModal: (url: string) => { }
};

const AppContext = createContext<IState>(initialState);

export const AppDataProvider: React.FC = ({ children }: IProps) => {
    const [appState, setAppState] = useState<IState>(initialState);

    const updateUser = useCallback(
        (value: IUser) => {
            setAppState({ ...appState, state: { user: value } });
        },
        [appState]
    );

    const resetUser = useCallback(() => {
        setAppState({ ...appState, state: { user: null } });
    }, [appState]);

    const resetModal = useCallback(
        () => {
            setAppState({
                ...appState, modal: {
                    contentType: '',
                    url: ''
                }
            });
        },
        [appState]
    );

    const openModal = useCallback(
        (value: IModalContent) => {
            setAppState({
                ...appState, modal: {
                    contentType: value.contentType,
                    url: value.url
                }
            });
        },
        [appState]
    );

    const closeMobileLoginModal = useCallback(
        () => {
            setAppState({
                ...appState,
                showMobileLoginModal: false,
                mobileLoginOption: LOGIN_URL
            });
        },
        [appState]
    );

    const openMobileLoginModal = useCallback(
        (url) => {
            setAppState({
                ...appState,
                showMobileLoginModal: true,
                mobileLoginOption: url
            });
        },
        [appState]
    );

    const contextValue = useMemo(
        () => ({ ...appState, updateUser, resetUser, resetModal, openModal, closeMobileLoginModal, openMobileLoginModal }),
        [appState, updateUser, resetUser, resetModal, openModal, closeMobileLoginModal, openMobileLoginModal]
    );

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppData = () => useContext(AppContext);
