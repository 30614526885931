import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import { Text, Box } from '@fivehealth/botero';

const Container: any = styled(Box)`
  background: ${(props: any) => props.actionStyle === 'link' ? 'inherit' : Colors.blue} !important;
  border-radius: ${(props: any) => props.actionStyle === 'link' ? 0 : 12}px !important;
  padding: 20px;
  color: ${(props: any) => props.actionStyle === 'link' ? Colors.blue : Colors.white};
  width: auto !important;
  margin: ${(props: any) => props.actionStyle === 'link' ? '0px 10px' : '10px 10px'};
  padding: 10px !important;
  cursor: pointer;
  border-top: 1px solid ${(props: any) => props.actionStyle === 'link' ? Colors.borderLine : Colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledText: any = styled(Text)`
  font-style: normal;
  font-weight: ${(props: any) => props.actionStyle === "link" ? 'bold' : 500};
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${(props: any) => props.actionStyle === "link" ? Colors.blue : Colors.white} !important;
  background: ${(props: any) => props.actionStyle === "link" ? 'inherit' : Colors.blue} !important;
`

interface IContent {
  content_type: string;
  text?: string;
  url: string;
  external_browser?: boolean;
  style?: string;
}

interface IActionButton {
  content: IContent;
  onPress: (content: any) => void
}

const ActionButton = (props: IActionButton) => {
  const { text, style } = props.content
  return (
    <Container actionStyle={style || 'cta'}
      onClick={() => { props.onPress(props.content) }}
    >
      <StyledText actionStyle={style || 'cta'}>
        {text}
      </StyledText>
    </Container>
  );
};

export default ActionButton;
