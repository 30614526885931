import { Box, Flex, Body, FAIcon, theme, useAuth } from '@fivehealth/botero';
import { Link, useLocation } from 'react-router-dom';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import menusItems from '../Menus';
import { Colors } from '../../constants/colors';
import styled from 'styled-components';
import LogoWithIndicator from '../LogoWithIndicator';
import { useAppData } from '../../context/AppDataContext';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { APP_INFO } from '../../constants/variables';
import { get } from 'lodash';

interface IMenuItem {
  path: string
  icon: any
  active: boolean
  label: string
  show: boolean;
  marginLeft?: string
  onClick?: () => void
}

const StyledLink: any = styled(Link)`
  text-decoration: none;
  visibility: ${(props: any) => props.show === 'true' ? 'visible' : 'hidden'};
  &:hover {
    background-color: red !important;
  }
`

const StyledFlex = styled(Flex)`
  min-width: 250px;
  :hover {
    background-color: ${Colors.menuGrey} !important;
  }
`


const MenuItem = (props: IMenuItem) => (
  <StyledLink show={props.show.toString()} onClick={props.show ? props.onClick : {}} to={props.path}>
    <StyledFlex
      bg={props.active ? '#F4F6F8' : '#FEFEFF'}
      borderRadius={8}
      px={2}
      py="10px"
      mb={1}
      alignItems="end"
    >
      <Box style={{ width: 30, maxWidth: 30 }}>
        <FAIcon
          icon={props.icon}
          style={{
            fontSize: 18,
            color: props.active ? theme.colors.primary : Colors.darkestShade,
            paddingRight: 16,
            marginLeft: props.marginLeft || 0
          }}
        />
      </Box>
      <Body
        fontWeight={props.active ? 600 : 500}
        lineHeight={"19px"}
        color={props.active ? theme.colors.fullShade : Colors.darkestShade}>
        {props.label}
      </Body>
    </StyledFlex>
  </StyledLink>
);

const StyledBox = styled(Box)`
  border-right: 1px solid ${Colors.borderLine};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  flex-direction: column;
`
const StyledBody = styled(Body)`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`

const Info = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19.36px;
  margin-bottom: 2px;
  color: ${Colors.black}
`

const MenuLink = ({ label, onClick }: any) => {
  return (
    <StyledBody
      fontSize={14}
      lineHeight={"17px"}
      cursor="pointer"
      my={2}
      onClick={onClick}
      color={theme.colors.fullShade}>
      {label}
    </StyledBody>
  );
}



const Sidebar = (props: any) => {
  const location = useLocation();
  const { state } = useAppData()
  const { authState, logout } = useAuth();
  const [menus, setMenus] = useState<any>([])
  const [orgLogo, setOrgLogo] = useState('')
  const [cookies] = useCookies([APP_INFO.cookieName]);

  useEffect(() => {
    if (!authState.authenticated) {
      setMenus(menusItems.filter(menu => !menu.requireLogin))
    } else {
      setMenus(menusItems)
    }
  }, [authState])

  useEffect(() => {
    if (state.user) {
      setOrganisationLogo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user])

  const handleLinkClick = (url: string) => {
    window.open(url, "_blank")
  }

  const setOrganisationLogo = () => {
    const modules = get(state, 'user.hospital.settings.legacy.modules', undefined)
    if (modules) {
      const profileModule = modules.find((item: any) => item.key === 'profile');
      if (profileModule) {
        const logo = profileModule.metadata.org_url;
        setOrgLogo(logo)
      }
    }
  }

  return (
    <StyledBox
      minHeight={[0, "81vh"]}
      height={[0, "auto"]}
      maxWidth={[0, 250]}
      justifyContent={authState.authenticated ? "space-evenly" : "space-around"}
      display={["none", "flex"]}
      bg={Colors.white} pl={3} pr={3} {...props}>
      <div style={{ paddingTop: 12, display: "flex", flexDirection: "column" }}>
        <div>
          <FAIcon
            icon={faEllipsisH}
            style={{
              fontSize: 16,
              color: Colors.blue,
              float: "right",
            }}
          />
        </div>

        <LogoWithIndicator
          containerSize={80}
          logoSize={40}
          backgroundColor={Colors.sidebarLogoBg}
          imageUrl={orgLogo}
          indicatorType="image"
          indicatorStyles={{
            'margin-top': '50px',
            'margin-left': '65px',
          }}
        />

        {authState.authenticated && (<div style={{
          marginTop: 16
        }}>
          <Info>
            {state?.user?.firstName} {state?.user?.lastName}
          </Info>
          <Info style={{ fontWeight: "normal", marginBottom: 0 }}>
            {state?.user?.designation && `${state?.user?.designation}, `}{state?.user?.department}
          </Info>
        </div>)}
      </div>

      <Box pt={3}>
        {menus.map((menu: any) => (
          <MenuItem
            show={menu.show.toString()}
            label={menu.name}
            icon={menu.icon}
            path={menu.path}
            active={menu.path === location.pathname}
            marginLeft={menu.marginLeft}
            key={menu.id}
            onClick={menu.onClick}
          />
        ))}
      </Box>

      <Box pt={3}>
        <MenuLink
          onClick={() => handleLinkClick(`https://bot-md.typeform.com/to/cw6hXn#user_uuid=${state.user.uid}&device_uuid=${cookies[APP_INFO.cookieName].channelUid}&institution=${state.user.hospital.settings.organization_key}`)}
          label="Give feedback" />
        <MenuLink
          onClick={() => handleLinkClick("https://www.botmd.io/en/privacy.html")}
          label="Privacy policy" />
        <MenuLink
          onClick={() => handleLinkClick("https://www.botmd.io/en/terms.html")}
          label="Terms of use" />
        {authState.authenticated && (
          <MenuLink
            onClick={() => { logout() }}
            label="Log out"
          />
        )}
      </Box>
    </StyledBox>
  );
};

export default Sidebar;
