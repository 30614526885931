import styled from 'styled-components';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { updateStitchV2ToStitchV3 } from '../../utils/appUtils';

interface IChatImage {
  imgUrl: string;
  position?: string;
  isSection?: boolean;
  authState: any;
}

const StyleImageDiv: any = styled.div`
  height: 115px;
  max-height: 115px;
  min-height: 115px;
  padding-bottom: 10px !important;
  background-image: url(${(props: any) => props.src}) !important;
  background-position: center !important;
  background-size: ${(props: any) => props.position} !important;
  width: auto !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  ${(props: any) => !props.isSection && { "border-radius": "12px" }};
`

const ChatImage = (props: IChatImage) => {
  const [showImage, setShowImage] = useState(false);

  const getBgPosition = (scale?: string) => {
    switch (scale) {
      case "center":
        return "contain"
      case "center-crop":
        return "cover"
      case "center-inside":
        return "contain"
      default:
        return "contain"
    }
  }

  return (
    <>
      {showImage && (
        <Lightbox
          mainSrc={updateStitchV2ToStitchV3(props.authState, props.imgUrl)}
          onCloseRequest={() => setShowImage(false)}
        />
      )}
      <StyleImageDiv
        style={{
          marginTop: props.position === 'center-crop' ? 0 : 8
        }}
        onClick={() => { setShowImage(true) }}
        src={updateStitchV2ToStitchV3(props.authState, props.imgUrl)}
        position={getBgPosition(props.position)}
        isSection={props.isSection}
      />
    </>
  );
};

export default ChatImage;
