import { gql } from "graphql-request";
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT: any = gql`
  mutation heimdallAuthorizationFlow($input: HeimdallAuthorizationFlowInput!) {
    heimdallAuthorizationFlow(input: $input) {
      session {
        id
        uid
        scopes
        expiresOn
        user {
          uid
        }
      }
      redirectTo
    }
  }
`


export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: "mutation",
  baseQueryKey: "heimdallAuthorizationFlow",
});
