import { Box, theme } from '@fivehealth/botero';
import Lottie from 'react-lottie-player'
import lottieJson from '../../assets/lotties/chat-typing-indicator.json'
import { Colors } from '../../constants/colors';
import LogoWithIndicator from '../LogoWithIndicator';

// create a component
const BotTypingIndicator = () => {
  return (
    <Box pl="20px" flexDirection="row" display="flex" alignItems={"center"}>
      <Box display="inline-block" mb={["16px", "8px"]}
        height={["24px", "52px"]}
        maxHeight={["24px", "52px"]}
        maxWidth={["24px", "46px"]}
        width={["24px", "46px"]}
      >
        <LogoWithIndicator containerPadding={2} borderColor={Colors.borderLine} logoSize={24} />
      </Box>
      <Box ml="8px" borderRadius="12px" mt="-15px" mb="15px" bg={theme.colors.lightShade}>
        <Lottie
          loop
          animationData={lottieJson}
          play
          style={{ height: 40, maxWidth: 46 }}
        />
      </Box>
    </Box>
  );
};

export default BotTypingIndicator;
