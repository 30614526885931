import { theme } from "@fivehealth/botero"

export const Colors = {
  blue: theme.colors.primary,
  bubbleGrey: "#EDEDED",
  menuGrey: theme.colors.lightestShade,
  black: theme.colors.fullShade,
  white: theme.colors.emptyShade,
  borderLine: theme.colors.mediumShade,
  green: theme.colors.success,
  sidebarLogoBg: "#a8c4fc",
  quickReplyHoverBg: "#E6F7FF",
  darkestShade: theme.colors.darkestShade,
  lightGray: '#A3A9B1'
}