import React, { useState, useEffect } from "react";
import { Box } from "@fivehealth/botero";
import styled from "styled-components";
import { assets } from "../../assets/images";
import { Colors } from "../../constants/colors";

interface ICarouselContainer {
  children: any;
  show: number;
}

const DRAG_SPEED = 3;

const CarouselMainContainer = styled(Box)`
  width: 96%;
  display: flex;
  flex-direction: column;
  margin-bottom: -20px;
`;

const CarouselWrapper = styled(Box)`
  display: flex;
  width: 100%;
  position: relative;
`;

const CarouselContainerWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CarouselContent = styled(Box)`
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 402px;
  max-height: 402px;
  & > * {
    flex-shrink: 0;
    flex-grow: 1;
    margin-right: 5px;
    border: 1px solid #E8EAED;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-self: end;
    height: 400px;
  }

  &.show-2 > * {
    width: calc(97.5% / 2)% !important;
    box-sizing: border-box;
    height: inherit;
    display: inline-grid;
    flex-direction: column;
  }

  &.show-3 > * {
    width: calc(97.5% / 3) !important;
  }

  &.show-4 > * {
    width: calc(97.5% / 4) !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Arrow: any = styled(Box)`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  cursor: pointer;
  ${({ arrowRight }: any) => (arrowRight ? "& > img { float: right }" : "")}
`;

const Indicator = styled(Box)`
  background-color: ${Colors.lightGray};
  height: 6px;
  width: 6px;
  border-radius: 50%;
  align-self: center;
  margin-top: 10px;
  margin-right: 5px;
`;

const IndicatorContainer = styled(Box)`
  display: flex;
  align-self: center;
`;

const CarouselContainer = (props: ICarouselContainer) => {
  const { children, show } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children.length);
  const [touchPosition, setTouchPosition] = useState(null);

  useEffect(() => {
    setLength(children.length);
  }, [children]);

  const handleTouchStart = (e: any) => {
    const touchIndex = e.touches[0].clientX;
    setTouchPosition(touchIndex);
  };

  const handleDrag = (e: any) => {
    const touchDownPostion = touchPosition;

    if (touchDownPostion === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDownPostion - currentTouch;

    if (diff > DRAG_SPEED) {
      next();
    }

    if (diff < -DRAG_SPEED) {
      prev();
    }
    setTouchPosition(null);
  };

  const next = () => {
    if (currentIndex < length / props.show - 1) {
      setCurrentIndex((prevState) => {
        return prevState + 1;
      });
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  return (
    <CarouselMainContainer>
      <CarouselWrapper>
        {currentIndex > 0 && (
          <Arrow left={"-30px"} onClick={prev}>
            <img alt="left-arrow" src={assets.leftArrow} />
          </Arrow>
        )}
        <CarouselContainerWrapper
          onTouchStart={handleTouchStart}
          onTouchMove={handleDrag}
        >
          <CarouselContent
            width={["77%", "auto"]}
            style={{
              transform: `translateX(-${currentIndex * (100 / show) * show}%)`,
            }}
            className={`show-${show}`}
          >
            {children}
          </CarouselContent>
        </CarouselContainerWrapper>
        {currentIndex < length / props.show - 1 && (
          <Arrow right={[0, "-30px"]} arrowRight={true} onClick={next}>
            <img alt="right-arrow" src={assets.rightArrow} />
          </Arrow>
        )}
      </CarouselWrapper>
      <IndicatorContainer>
        {new Array(length).fill(0).map((_, i: number) => (
          <Indicator key={i} />
        ))}
      </IndicatorContainer>
    </CarouselMainContainer>
  );
};

export default CarouselContainer;
