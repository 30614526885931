import botLogo from "./bot-logo.svg";
import botLogoChat from "./bot-logo-chat.svg";
import homeBtn from "./home-btn.svg";
import botLogoWithText from "./bot-logo-with-text.svg";
import leftArrow from "./left-arrow.svg";
import rightArrow from "./right-arrow.svg";
import HamburgerMenu from "./hamburger-menu.svg";
import CloseBtn from "./close-button.svg";
import SearchIcon from "./search-icon.svg";
import FavoriteIcon from "./favorite-icon.svg";
import DocAvatar from "./doc-avatar.svg";
import audioPlayback from "./App-audio-playback-image.png";

export const assets = {
  botLogo,
  homeBtn,
  botLogoChat,
  botLogoWithText,
  leftArrow,
  rightArrow,
  HamburgerMenu,
  CloseBtn,
  SearchIcon,
  FavoriteIcon,
  DocAvatar,
  audioPlayback,
};
