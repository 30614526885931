import { isLocalOrStaging } from "../utils/appUtils";

// eslint-disable-next-line no-useless-escape
export const PhoneNumberRegex = /([\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6})/

interface PublicProvider {
  id: string
  organisationKey: string
  hideInput: boolean
}

export const PUBLIC_PROVIDERS: Record<string, PublicProvider> = {
  ncis: {
    id: isLocalOrStaging() ? 'VaRb4fMqrzHnouEUV7s1jh6w864ZMXyE' : 'AErGgC1yEwE7FWQIrlkF8Bw1vENV1eah',
    organisationKey: 'ncis_engage_web',
    hideInput: false
  },
  idi_web: {
    id: isLocalOrStaging() ? 'VaRb4fMqrzHnouEUV7s1jh6w864ZMXyE' : 'AErGgC1yEwE7FWQIrlkF8Bw1vENV1eah',
    organisationKey: 'idi_web',
    hideInput: false
  },
  faithful_empire_web: {
    id: isLocalOrStaging() ? 'VaRb4fMqrzHnouEUV7s1jh6w864ZMXyE' : 'AErGgC1yEwE7FWQIrlkF8Bw1vENV1eah',
    organisationKey: 'faithful_empire_web',
    hideInput: false
  },
  botmd_id_web: {
    id: isLocalOrStaging() ? 'VaRb4fMqrzHnouEUV7s1jh6w864ZMXyE' : 'AErGgC1yEwE7FWQIrlkF8Bw1vENV1eah',
    organisationKey: 'botmd_id_web',
    hideInput: false
  }
}

const PUSHER_ID = new URLSearchParams(document.location.search).get(
  "pusherId"
)

console.log({ PUSHER_ID })

export const ORG_WITH_PUBLIC_SESSION = Object.keys(PUBLIC_PROVIDERS)

export const APP_INFO = {
  loginDomain: isLocalOrStaging() ? 'https://login.staging.botmd.io' : 'https://login.production.botmd.io',
  redirectUri: `${window.location.origin}/login/callback`,
  redirectPath: '/login/callback',
  providerId: isLocalOrStaging() ? 'ko0gL1DpIr3qus9wJ1tkOdepZSI73EV3' : 'r8ZcB9hjX6Qz6GfuUl0zY39nkcLK40Jo',
  qrCodeProviderId: isLocalOrStaging() ? 'RhN65gjPCeD5ZciUe83zSnMd51QnxaPO' : 'DyGVlwW57WN4zf5ZhwXLezqjKUUq5r73',
  cookieName: 'botMDWebMessenger',
  pusherApplicationId: (isLocalOrStaging() ? 'FUuQ1kkxt9z3Zw5NfnC9Cxvy6kGZpRDc' : 'nbvhyCzrl1HLouU0xOojC9ZrFymzcJ2q'),
  loggedInUserTimestamp: 'loggedInUserTimestamp',
  rocheSignUpForm: `${`https://hippocrates.${isLocalOrStaging() ? 'staging' : 'production'}.botmd.io/hospital/roche_hcp`}`,
  gqlEndpoint: !isLocalOrStaging()
    ? 'https://hippocrates.production.botmd.io/gql/'
    : 'https://hippocrates.staging.botmd.io/gql/'
}

export const LOGIN_URL = `${APP_INFO.loginDomain}/?uid=${APP_INFO.providerId}&redirectTo=${APP_INFO.redirectUri}`;
export const QrCodeLOGIN_URL = `${APP_INFO.loginDomain}/?uid=${APP_INFO.qrCodeProviderId}&redirectTo=${APP_INFO.redirectUri}&qrcode=true`;

export const IS_FORMULARY_REGEX = /botmd\.io.*chernobyl|chernobyl.*botmd\.io/gi;