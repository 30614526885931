import { gql } from "graphql-request";
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT: any = gql`
  mutation hospitalJarvisQuery($input: HospitalJarvisQueryInput!) {
    hospitalJarvisQuery(input: $input) {
      deliveries {
      messageContent
      metadata
      createdOn
      direction
      uid
      }
      messages
      metadata
    }
  }
`


export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: "mutation",
  baseQueryKey: "hospitalJarvisQuery",
});
