import { gql } from "graphql-request";
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_HOSPITAL_REGISTERABLE_DEVICE: any = gql`
  query hospitalRegisterableDevice($deviceUid: String!) {
    hospitalRegisterableDevice(deviceUid: $deviceUid) {
      registerable
    }
  }
`


export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_HOSPITAL_REGISTERABLE_DEVICE,
  queryType: "query",
  baseQueryKey: "hospitalRegisterableDevice",
});
