import React from 'react';
import CarouselContainer from '../CarouselContainer';
import { Box } from '@fivehealth/botero';
import useDeviceDetect from '../../hooks/useDeviceDetect';

interface IChatCarousel {
  data: any;
  variables: any;
  renderItem: (
    message: any, nodeType: string,
    variables: any, key: any,
    isSection: boolean) => React.ReactNode;
}

const ChatCarousel = (props: IChatCarousel) => {

  const { isMobile } = useDeviceDetect();

  const show = isMobile ? 1 : 3;

  const { data, variables, renderItem } = props;
  const { carousel } = data;

  return (
    <Box>
      <CarouselContainer show={show}>
        {
          carousel.map((sections: any, idx: number) => {
            return (
              <Box key={idx} style={{ width: `${100 / show}%` }}>
                {renderItem(sections, "", variables, idx, true)}
              </Box>
            )
          })
        }
      </CarouselContainer>
    </Box>

  );
};

export default ChatCarousel;
