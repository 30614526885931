import { gql } from "graphql-request";
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  mutation hospitalPostMessage($input: HospitalPostMessageInput!) {
    hospitalPostMessage(input: $input) {
      message {
        uid
        direction
        messageContent
        metadata
        createdOn
      }
      device {
        metadata
        createdOn
      }
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: "mutation",
  baseQueryKey: "postMessage",
  keepPreviousData: true,
});
