import { gql } from "graphql-request";
import { createQuery } from '@fivehealth/botero';

export const GRAPHQL_DOCUMENT_HEIMDALL_SESSION = gql`
  query byUid($uid: String!) {
    heimdallSession(uid: $uid) {
      id
      uid
      user {
        uid
      }
      scopes
      expiresOn
    }
  }
`


export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT_HEIMDALL_SESSION,
  queryType: "query",
  baseQueryKey: "heimdallSession",
  keepPreviousData: false
});
