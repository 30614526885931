import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import LogoWithIndicator from '../LogoWithIndicator';
import { Box } from '@fivehealth/botero';
import { getMessageTimeStamp } from '../../utils/appUtils';

interface IChatBubble {
  children: React.ReactNode;
  direction: string;
  createdOn: string;
  clearBackground?: boolean;
  isCarousel?: boolean;
  isLast?: boolean;
  showAllMessageTimeStamp?: boolean;
  width?: number | string | undefined;
  minWidth?: number | string | undefined;
}

const ReceivedInnerWrapper = styled(Box)`
  display: inline-block;
  padding: 0 0 0 8px;
  vertical-align: bottom;
  max-width: ${(props: any) => props.isCarousel ? 87 : 70}%;
  width: ${(props: any) => props.isCarousel ? '87%' : props.width || '70%'};
`

const ReceivedMessageContainer: any = styled(Box)`
  background: ${(props: any) => props.clearBackground ? 'transparent' : '#E8EAED'} none repeat scroll 0 0;
  border-radius: 12px;
  margin: 0;
  font-family: Inter;
  white-space: pre-line;
  border: ${(props: any) => !props.isCarousel ? '1px solid #E8EAED' : 'unset'};

  & > div {
    padding: ${(props: any) => !props.isCarousel ? '5px 10px 5px 12px' : 0};
    padding: 5px 0px 0px 0px;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
  }
`

const SentContainer = styled(Box)`
  float: right;  
  & > div {
    background: #256BF7 none repeat scroll 0 0;
    border-radius: 12px;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #fff;
    padding: 8px 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-family: Inter;
  }
`

const Username = styled.span`
  color: ${Colors.lightGray};
  font-size: 12px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  margin: 0 0  0 10px;
`

const TimeDate: any = styled.span`
  color: ${Colors.lightGray};
  display: block;
  font-size: 12px;
  margin: 8px 0 8px 8px;
  font-style: normal;
  font-weight: normal;
  line-height: 10px;

  
  ${({ direction, isLast }: any) => direction === "outgoing"
    ? `
    background: white;
    float: left;
    ${isLast && 'margin-bottom: 0px;'}
    `
    : `
    background: white;
    float: right;
    `
  }
`

const SentWrapper = styled(Box)`
  overflow:hidden;
`

const ReceivedWrapper = styled(Box)`
  padding-left: 20px;
  margin-bottom: 20px;
  flex: auto;
`

const ChatBubble = (props: IChatBubble) => {

  if (props.direction === 'incoming') {
    return (
      <SentWrapper margin={["0px", "26px 0 26px"]} pr={["20px", "60px"]}>
        <SentContainer maxWidth={["80%", "46%"]}>
          {props.children}
          <TimeDate direction="incoming">{(props.isLast || props.showAllMessageTimeStamp) && getMessageTimeStamp(props.createdOn)}</TimeDate>
        </SentContainer>
      </SentWrapper>
    )
  } else {
    return (
      <ReceivedWrapper flexDirection="row" display="flex" alignItems={"flex-end"}>
        <Box display="inline-block" mb={["16px", "8px"]} height={["24px", "52px"]} width={["24px", "46px"]}>
          <LogoWithIndicator containerPadding={2} borderColor={Colors.borderLine} logoSize={24} />
        </Box>
        <ReceivedInnerWrapper width={props.width} isCarousel={props.isCarousel}>
          {props.isLast && <Username>{"Bot MD"}</Username>}
          <ReceivedMessageContainer
            width={["100%", `${props.isCarousel ? "100%" : "fit-content"}`]}
            maxWidth={["100%", `${props.isCarousel ? 100 : 60}%`]}
            minWidth={[0, props.minWidth || '60px']}
            isCarousel={props.isCarousel}
            clearBackground={props.clearBackground}>
            {props.children}
            <TimeDate isLast={props.isLast} direction="outgoing">{(props.isLast || props.showAllMessageTimeStamp) && getMessageTimeStamp(props.createdOn)}</TimeDate>
          </ReceivedMessageContainer>
        </ReceivedInnerWrapper>
      </ReceivedWrapper>
    )
  }
};

export default ChatBubble;
