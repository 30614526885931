import { useState, useEffect } from 'react';
import { Box, InputField } from '@fivehealth/botero';
import styled from 'styled-components';
import { assets } from '../../assets/images';
import { useAppData } from '../../context/AppDataContext';
import { get } from 'lodash';

const Title = styled(Box)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
`

const StyledImg = styled.img`
  height: 20px;
`

const StyledInput = styled(InputField)`
  border: 1px solid #D5D9DE;
  box-sizing: border-box;
  border-radius: 32px;
  &:focus{
    outline: none;
  }
`

const FavoriteItemContainer = styled(Box)`
  border: 1px solid #D5D9DE;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  overflow-y: scroll;
  cursor: pointer;
`

const Text = styled(Box)`
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
`

const Favorites = () => {

  const [searchTerm, setSearchTerm] = useState('')
  const [favorites, setfavorites] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const { state, openModal } = useAppData()

  useEffect(() => {
    if (state.user) {
      getFavorites()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user])

  useEffect(() => {
    if (searchTerm.length > 0) {
      const filteredFavs = favorites.filter((item: any) => item.title.toLowerCase().includes(searchTerm.toLowerCase()) > 0)
      setFilteredData(filteredFavs)
    }
  }, [searchTerm, favorites])

  const getFavorites = () => {
    if (state.user) {
      const modules = get(state.user, 'hospital.settings.legacy.modules')
      if (modules) {
        const favs = modules.find((item: any) => item.key === 'favorites').metadata.links
        setfavorites(favs)
        setFilteredData(favs)
      }
    }
  }

  const onPressed = (url: string) => {
    openModal({ contentType: 'html/text', url })
  }

  const renderFavorites = (item: any) => {
    return (
      <FavoriteItemContainer onClick={() => onPressed(item.url)} key={item.key} display="flex" flex="1" flexDirection="row" mb="10px" p="10px">
        <Box display="flex" flexDirection="column">
          <Text fontWeight="600" pb="10px">
            {item.title}
          </Text>
          <Text color={"#92969a"} fontWeight="normal">
            {item.subtitle}
          </Text>
        </Box>
        <Box>
          <StyledImg alt="" src={assets.FavoriteIcon} style={{ height: 22 }} />
        </Box>
      </FavoriteItemContainer>
    )
  }

  return (
    <Box overflow="hidden"
      display="flex"
      flexDirection="column"
      pl={["10px", "35px"]}
      pr={["10px", "35px"]}
      pt={["40px", "0px"]}
      height={["calc(100vh - 110px)", "81vh"]}
    >
      <Box>
        <Title pt={["15px", "35px"]} mb={["0", "20px"]} fontSize={["20px", "40px"]}>
          Favorites
        </Title>
        <StyledInput
          onChange={(e: any) => {
            setSearchTerm(e.target.value)
          }}
          value={searchTerm}
          width={"100%"}
          startIcon={<StyledImg src={assets.SearchIcon} />}
          placeholder="Enter search term" />
      </Box>

      <Box overflowY="scroll">
        <Box mt="20px">
          {(searchTerm ? filteredData : favorites).map(item => renderFavorites(item))}
        </Box>
      </Box>
    </Box>
  );
};

export default Favorites;
