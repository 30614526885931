import { DateTime } from "luxon";
import { getLoggedInTime, getSortMethod } from "./appUtils";

export const sortMessages = (data: any, oldVal: any = []) => {

    const loggedInTimestamp = getLoggedInTime()

    const newMessage = data;
    const concatenatedMessages = oldVal.concat(newMessage)
    let destructedMessages = concatenatedMessages.map((msg: any) => {
        return { ...msg.node }
    })

    if (loggedInTimestamp) {
        destructedMessages = destructedMessages
            .filter((msg: any) => DateTime.fromISO(msg.createdOn) >= DateTime.fromISO(loggedInTimestamp))
    }

    let linkedList = {};
    destructedMessages.forEach((msg: any) => {
        linkedList = {
            ...linkedList,
            [`${msg.uid}`]: msg
        }
    });

    destructedMessages = Object.values(linkedList)

    const sortedDestructedMessages = destructedMessages.sort(getSortMethod("+createdOn"))
    const sortedResultRestructured = sortedDestructedMessages.map((item: any) => {
        return {
            node: item
        }
    })
    return sortedResultRestructured
};
